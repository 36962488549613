// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 专业建设
// 数据概览
export const getMajorBuild = params => {
  return request({
    url: '/api/project/getMajorBuild',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库专业建设项目展示
export const getProjectBuildContribution = params => {
  return request({
    url: '/api/project/getProjectBuildContribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库专业建设项目详细
export const getProjectResourceDistribution = params => {
  return request({
    url: '/api/project/getProjectResourceDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志曲线
export const GetProjectSchoolHourLog = params => {
  return request({
    url: '/api/project/GetProjectSchoolHourLog',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库专业建设项目列表
export const getProjectList = params => {
  return request({
    url: '/api/project/getProjectList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 教师资源建设详情
export const getProjectUserList = params => {
  return request({
    url: '/api/project/getProjectUserList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 导出资源库专业建设项目列表
export const exportProjectList = params => {
  return request({
    url: '/api/project/exportProjectList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 导出教师资源建设详情
export const exportProjectUserList = params => {
  return request({
    url: '/api/project/exportProjectUserList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 资源建设
// 资源库建设状态-资源库（图表）
export const getProjectMaterialTypeStatistic = params => {
  return request({
    url: '/api/resource/getProjectMaterialTypeStatistic',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库建设状态-资源库（列表）
export const getProjectMaterialTypeStatisticList = params => {
  return request({
    url: '/api/resource/getProjectMaterialTypeStatisticList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库建设状态-资源库（列表）
export const exportProjectMaterialTypeStatisticList = params => {
  return request({
    url: '/api/resource/exportProjectMaterialTypeStatisticList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 专业列表
export const getProjectId = params => {
  return request({
    url: '/api/resource/getProjectId',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库资源建设——按资源来源统计
export const getSourceStatistic = params => {
  return request({
    url: '/api/resource/getSourceStatistic',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库资源建设——活跃资源占比
export const getActiveResources = params => {
  return request({
    url: '/api/resource/getActiveResources',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库资源建设——资源类型分布
export const GetMediaTypeStatistic = params => {
  return request({
    url: '/api/resource/GetMediaTypeStatistic',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库资源建设——新增资源类型分布
export const getAddResource = params => {
  return request({
    url: '/api/resource/getAddResource',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库资源建设——资源建设列表
export const getResourcesBuild = params => {
  return request({
    url: '/api/resource/getResourcesBuild',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源库资源建设——资源建设列表(导出)
export const exportResourcesBuild = params => {
  return request({
    url: '/api/resource/exportResourcesBuild',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 课程建设

// 课程统计
export const getZykCourse1 = params => {
  return request({
    url: '/api/resourceCourse/getZykCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程建设
export const getZykCourseList = params => {
  return request({
    url: '/api/resourceCourse/getZykCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 导出课程建设
export const exportZykCourseList = params => {
  return request({
    url: '/api/resourceCourse/exportZykCourseList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 课程详情头部
export const getCourseNameAuthorRepository = params => {
  return request({
    url: '/api/resourceCourse/getCourseNameAuthorRepository',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程资源分布
export const getCourseCellTypeDistribution = params => {
  return request({
    url: '/api/resourceCourse/getCourseCellTypeDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 各校用户及活跃度
export const getSchoolDistribution2 = params => {
  return request({
    url: '/api/resourceCourse/getSchoolDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程日志量
export const getLogDayStatistics2 = params => {
  return request({
    url: '/api/resourceCourse/getLogDayStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日活学生数
export const getDayStuStatistics = params => {
  return request({
    url: '/api/resourceCourse/getDayStuStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 月活学生数
export const getMonthStuStatistics = params => {
  return request({
    url: '/api/resourceCourse/getMonthStuStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 发帖统计
export const getIcvePostingStatistics = params => {
  return request({
    url: '/api/resourceCourse/getIcvePostingStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学生学习详情
export const getStudentStudyList = params => {
  return request({
    url: '/api/resourceCourse/getStudentStudyList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 排行榜
// 排行榜——活跃资源top10
export const getResourceTop = params => {
  return request({
    url: '/api/ranking/getResourceTop',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 排行榜——活跃教师top10
export const getTeaTop2 = params => {
  return request({
    url: '/api/ranking/getTeaTop',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 排行榜——活跃课程top10
export const getZykStandardCourseTop = params => {
  return request({
    url: '/api/ranking/getZykStandardCourseTop',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课程调用
// 课程引用详情列表
export const getInvokedCourse = params => {
  return request({
    url: '/api/courseInvoked/getInvokedCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程引用详情
export const getInvokedCourseAll = params => {
  return request({
    url: '/api/courseInvoked/getInvokedCourseAll',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 被引用课程应用详情
export const getInvokedCourseDetailList = params => {
  return request({
    url: '/api/courseInvoked/getInvokedCourseDetailList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学校引用详情列表
export const getInvokedSchool = params => {
  return request({
    url: '/api/courseInvoked/getInvokedSchool',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 导出课程引用详情列表
export const exportInvokedCourse = params => {
  return request({
    url: '/api/courseInvoked/exportInvokedCourse',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 导出被引用课程应用详情
export const exportInvokedCourseDetailList = params => {
  return request({
    url: '/api/courseInvoked/exportInvokedCourseDetailList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 导出学校引用详情列表
export const exportInvokedSchool = params => {
  return request({
    url: '/api/courseInvoked/exportInvokedSchool',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
