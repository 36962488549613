<template>
  <div class="common-head">
    <div class="headLeft">
      <div class="headLogo flex-col"></div>
      <span class="title" @click="sfShows" v-show="!sfShow">
        省级数据中心-{{ province }}
        <i class="el-icon-caret-bottom"></i>
      </span>
      <span v-show="sfShow" class="sfSelect">
        <div>
          <!-- <el-input placeholder="选择或输入省级数据中心" type="text" name="" v-model="state1"></el-input> -->
          <el-autocomplete
            class="inline-input"
            v-model="state1"
            ref="inputRef"
            :fetch-suggestions="querySearch"
            placeholder="选择或输入搜索切换省级数据中心"
            @select="handleSelect"
            @keyup="changeStyle('block', '.el-autocomplete-suggestion')"
            @blur="inputBlur"
          >
            <template slot-scope="{ item }">
              <div v-if="!!item.value" class="name">
                省级数据中心-{{ item.value }}
              </div>
            </template>
          </el-autocomplete>
          <i class="el-icon-caret-top title1" @click="inputBlur"></i>
        </div>
      </span>
    </div>
    <div class="headRight">
      <el-avatar :size="30" :src="userInfo.avatarUrl"></el-avatar>
      <el-dropdown @command="handleCommand">
        <span class="userName el-dropdown-link">
          {{ userInfo.userName
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item>个人空间</el-dropdown-item> -->
          <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      sfList: [],
      restaurants: [],
      province: "",
      state1: "",
      sfShow: false,
      userInfo: {},
    };
  },
  created() {
    this.getProvinceList();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.userInfo = userInfo;
    }
  },
  mounted() {},
  methods: {
    //  退出登录
    async handleCommand(command) {
      console.log(command);
      if (command === "loginOut") {
        let data = JSON.parse(localStorage.getItem("userInfo"));
        try {
          const { data: res } = await this.$api.loginOut({ token: data.token });
          if (res.code === 2000) {
            //localStorage.clear()
            this.$store.commit('ROUTER_FALSE')
            localStorage.removeItem("userInfo");
            this.$router.push({ path: "/Login" });
          } else {
            this.$message.error(res.msg);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    // toggleMenu () {
    //   this.$store.commit('nav/TOGGLE_MENU')
    // },
    // 请求省份
    async getProvinceList() {
      try {
        const { data: res } = await this.$api.getProvinceList();
        this.sfList = res.data;
        if (this.sfList.length > 0) {
          this.resChange();
        } else {
          this.$message.error("您没有访问权限");
          this.province = "";
          sessionStorage.setItem("sfprovince", "");
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 切换省份
    sfShows() {
      if (this.sfList.length > 1) {
        this.sfShow = true;
        this.inputFocus();
      }
    },
    // 省份列表
    resChange() {
      this.restaurants = [
        ...this.sfList.map((item) => ({ value: item.province })),
      ];
      this.restaurants.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });
      if (!this.province) {
        this.province = this.sfList[0].province;
        sessionStorage.setItem("sfprovince", this.province);
        // this.$router.go(0)
        this.$emit("mainKey");
      }
    },
    // 获取光标
    inputFocus() {
      setTimeout(() => {
        this.$refs.inputRef.$children[0].focus();
      }, 10);
    },
    // 失去光标触发
    inputBlur() {
      setTimeout(() => {
        this.sfShow = false;
      }, 250);
    },
    querySearch(queryString, cb) {
      console.log(queryString, "queryString");
      const restaurants = this.restaurants;
      const results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // console.log(restaurants)
      // console.log(results)
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toUpperCase().match(queryString.toUpperCase());
      };
    },
    handleSelect(item) {
      // console.log(item,this.state1,'kkk')
      sessionStorage.setItem("sfprovince", this.state1);
      this.province = this.state1;
      this.sfShow = false;
      this.changeStyle("none", ".el-autocomplete-suggestion");
      // this.state1 = ''
      // this.$router.go(0)
      this.$emit("mainKey");
      // this.$router.push({ path: '/component' });
      // this.$forceUpdate()
    },
    // 根据传进来的状态改变建议输入框的状态（展开|隐藏）
    changeStyle(status, className) {
      const dom = document.querySelectorAll(className);
      dom[0].style.display = status;
    },
    clickMenuItem(action) {
      if (action === "logout") {
        this.$store.commit("user/CLEAR_USER_INFO");
        this.$router.go(0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.common-head {
  height: 60px;
  width: 100%;
  max-width: 2500px;
  margin: 0 auto;
  background-color: rgba(65, 127, 242, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headLeft {
    display: flex;
    justify-content: left;
    align-items: center;

    .headLogo {
      width: 113px;
      height: 60px;
      background: url(@/assets/province/logo.png) center no-repeat;
      // background-size: 100% 100%;
      // margin-left:21px;
      margin-right: 20px;
    }

    .title {
      width: 209px;
      height: 24px;
      -webkit-text-stroke: 1px rgba(255, 255, 255, 1);
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      letter-spacing: 1px;
      // font-family: STSongti-SC;
      // font-weight: bold;
      text-align: left;
      white-space: nowrap;
      line-height: 24px;

      // margin: 18px 0 0 20px;
      .el-dropdown {
        font-size: 24px;
        color: #fff;
      }
    }

    .title1 {
      width: 209px;
      height: 24px;
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      margin-left: 10px;
    }

    .el-autocomplete {
      width: 244px;
    }
  }

  .headRight {
    display: flex;
    justify-content: right;
    align-items: center;

    .userInfo {
      border-radius: 50%;
      background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/31685b4…_mergeImage.png);
      width: 30px;
      height: 30px;
      margin: 15px 0 0 909px;
    }

    .userName {
      width: 42px;
      height: 20px;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      text-align: left;
      white-space: nowrap;
      line-height: 20px;
      margin: 20px 0 0 8px;
    }

    .el-dropdown-link {
      cursor: pointer;
    }

    .el-icon-arrow-down {
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss">
// 输入下拉框提示
.el-autocomplete-suggestion ul li {
  line-height: 40px;
  font-size: 20px;
}
</style>
