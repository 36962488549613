//  共有函数
// 数组排序问题
export const sortOpens = function (arr, name) {
  const list = arr.sort(function (a, b) {
    let val1 = Number(a[name]) 
    let val2 = Number(b[name]);
    // console.log(val1,val2,'kkk')
    return val1 - val2;
  });
  return list 
};
