// axios二次封装
import axios from 'axios'
import { Loading, Message } from 'element-ui'
import { getToken } from '_utils/token'
import router from '../router'
// import store from '../store'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  // baseURL:'/api',
  // baseURL:'https://datadev.icve.com.cn',
  timeout: 50000
})
// 拦截器
let loading = {}
request.interceptors.request.use(function (config) {
  // console.log(config,222);
  // 显示loading
  // loading = Loading.service({
  //   lock: true,
  //   text: '奋力加载中...',
  //   spinner: 'el-icon-loading',
  //   background: 'rgba(0, 0, 0, 0.7)'
  // })
  // 在请求头中添加token 进行接口鉴权
  const token = getToken()
  if (token) {
    config.headers.token = token
  }
  if (config.url.includes('gjpd')) {
    config.url =
      'https://ic.icve.com.cn/' + config.url.replace(/\/gjpd/, '')
  }
  if (config.url.includes('ai-api')) {
    config.url =
      'https://zjyw.icve.com.cn/' + config.url.replace(/\/ai-api/, '')
  }
  return config
}, function (error) {
  // loading.close()
  return Promise.reject(error)
})

request.interceptors.response.use(function (res) {
  const msg = res.data.msg
  if (res.data.code === -1 || res.data.msg === '您的登录状态已失效') {
    router.push('/Login')
    localStorage.removeItem('userInfo')
    // token过期 或者 未登录
    Message.error({
      type: 'error',
      message: '登录状态过期或者未登录',
      duration: 1500,
      // onClose () {
      // }
    })
  } else if (res.data.code == 500) {
    console.log(msg, 'hhaha');
    if(res.data.msg == '连接超时，请重新登录' || res.data.msg == 'token已过期') {
      localStorage.removeItem('userInfo')
      router.push('/Login')
    } else {
      Message({
        type: 'error',
        message: msg,
      })
    }
    // return Promise.reject(msg)
  }
  // loading.close()
  return res
}, function (error) {
  if (error.code === 'ECONNABORTED') {
    Message({
      type: 'error',
      message: '请求超时',
    }
    )
  } else {
    Message({
      type: 'error',
      message: '系统异常,请联系管理员',
    }
    )
  }
  // loading.close()
  return Promise.reject(error)
})

export default request
