<template>
  <div class="common-aside">
    <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      text-color="#52596F"
      router
      active-text-color="#fff">
      <div v-for="menu in $store.state.authorityMenuList" :key="menu.rightId">
        <el-menu-item  :index="menu.path" v-if="!menu.children">
          <img class="isHidd" :src="menu.icon" />
          <img class="isShow" :src="menu.iconA" />
          <span slot="title">{{menu.rightName}}</span>
        </el-menu-item>
        <el-submenu :index="menu.rightName" v-if="menu.children">
          <template slot="title">
            <img class="isHidd" :src="menu.icon" />
            <img class="isShow" :src="menu.iconA" />
            <span>{{menu.rightName}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="subMenu in menu.children"
              :key="subMenu.path"
              :index="subMenu.path">
              <img class="isShow" :src="subMenu.iconA" />
              <img class="isHidd" :src="subMenu.icon" />
              {{subMenu.rightName}}
              </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
       </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      menu2Show: true
    }
  },
  methods:{

  },
  created(){
  },
}
</script>
