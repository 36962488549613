// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 专业群

// 获取所属专业群列表
export const getProjectGroupBySchoolId = params => {
  return request({
    url: '/api/zyqStatistics/getProjectGroupBySchoolId',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 头部基本数据
export const getOverView = params => {
  return request({
    url: '/api/zyqStatistics/getOverView',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志曲线
export const getLogCurve = params => {
  return request({
    url: '/api/zyqStatistics/getLogCurve',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 活跃教师-活跃用户
export const getActiveTeaAndStu = params => {
  return request({
    url: '/api/zyqStatistics/getActiveTeaAndStu',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源统计
// 头部
export const getMaterialOverview = params => {
  return request({
    url: '/api/zyqStatistics/getMaterialOverview',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程列表
export const getUpCourseList = params => {
  return request({
    url: '/api/zyqStatistics/getUpCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 按媒体类型统计-按应用类型统计-素材来源分布图
export const getMaterialDistributed = params => {
  return request({
    url: '/api/zyqStatistics/getMaterialDistributed',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 活跃资源占比
export const getActiveResourceRate = params => {
  return request({
    url: '/api/zyqStatistics/getActiveResourceRate',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 素材来源占比
export const getFromplat = params => {
  return request({
    url: '/api/zyqStatistics/getFromplat',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 按课程分布素材
export const getCourseMatetial = params => {
  return request({
    url: '/api/zyqStatistics/getCourseMatetial',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 素材上传情况统计表
export const getUploadStatus = params => {
  return request({
    url: '/api/zyqStatistics/getUploadStatus',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 题库统计
// 按课程分布试题
export const getCourseQuestion = params => {
  return request({
    url: '/api/zyqStatistics/getCourseQuestion',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 统计
export const getQuestionDistributed = params => {
  return request({
    url: '/api/zyqStatistics/getQuestionDistributed',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程统计
// 课程分类
export const getCourseSource = params => {
  return request({
    url: '/api/zyqStatistics/getCourseSource',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程专业分布
export const getCourseMajor = params => {
  return request({
    url: '/api/zyqStatistics/getCourseMajor',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程层次分布
export const getCourseLevel = params => {
  return request({
    url: '/api/zyqStatistics/getCourseLevel',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程建设
export const getCurriculumDetail = params => {
  return request({
    url: '/api/zyqStatistics/getCurriculumDetail',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程建设导出
export const exportCurriculumDetail = params => {
  return request({
    url: '/api/zyqStatistics/exportCurriculumDetail',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
// 课程开设情况-课程资源与学习信息
export const getCurriculumDetailById = params => {
  return request({
    url: '/api/zyqStatistics/getCurriculumDetailById',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程统计——课程被调用详情（spoc-mooc）
export const getCourseInvokeSpoc = params => {
  return request({
    url: '/api/zyqStatistics/getCourseInvokeSpoc',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户详情——用户总数(按身份分布)
export const zyqStatisticsidentityStatistics = params => {
  return request({
    url: '/api/zyqStatistics/identityStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户详情——用户总数(按院校分布)
export const zyqStatisticscollegesStatistics = params => {
  return request({
    url: '/api/zyqStatistics/collegesStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户详情——每月新增注册用户
export const zyqStatisticsuserMonthRegisterStatistics = params => {
  return request({
    url: '/api/zyqStatistics/userMonthRegisterStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志统计——日志分布
export const zyqStatisticsgetLogSource = params => {
  return request({
    url: '/api/zyqStatistics/getLogSource',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志统计——按人员所在单位统计
export const zyqStatisticsgetUserSchool = params => {
  return request({
    url: '/api/zyqStatistics/getUserSchool',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志统计——按项目成员统计
export const zyqStatisticslogProjectUserStatistics = params => {
  return request({
    url: '/api/zyqStatistics/logProjectUserStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志统计——用户活动记录——用户详情
export const zyqStatisticsgetUserInfo = params => {
  return request({
    url: '/api/zyqStatistics/getUserInfo',
    method: 'post',
    data: qs.stringify(params)
  })
}
//日志统计——用户活动记录——日志量
export const zyqStatisticsgetLogLine = params => {
  return request({
    url: '/api/zyqStatistics/getLogLine',
    method: 'post',
    data: qs.stringify(params)
  })
}
//日志统计——用户活动记录——操作行为记录表
export const zyqStatisticsgetUserOperation = params => {
  return request({
    url: '/api/zyqStatistics/getUserOperation',
    method: 'post',
    data: qs.stringify(params)
  })
}
//考勤预警——学生出勤率预警
export const teachingWarngetStudentAvgAttendanceWarn = params => {
  return request({
    url: '/api/teachingWarn/getStudentAvgAttendanceWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//考勤预警——班级出勤率预警
export const teachingWarngetClassAvgAttendanceWarn = params => {
  return request({
    url: '/api/teachingWarn/getClassAvgAttendanceWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//考勤预警——预警学生院系分布
export const teachingWarngetSubStudentWarn = params => {
  return request({
    url: '/api/teachingWarn/getSubStudentWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//成绩预警——课程作业完成率
export const teachingWarngetCourseHomeworkCompleteAvgWarn = params => {
  return request({
    url: '/api/teachingWarn/getCourseHomeworkCompleteAvgWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//成绩预警——学生作业完成率
export const teachingWarngetStudentHomeworkCompleteAvgWarn = params => {
  return request({
    url: '/api/teachingWarn/getStudentHomeworkCompleteAvgWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//教学行为预警——课程列表
export const teachingWarngetTeacherHomeworkMakeAvgWarn = params => {
  return request({
    url: '/api/teachingWarn/getTeacherHomeworkMakeAvgWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//教学行为预警——未登录教师信息
export const teachingWarngetTeacherNotLoginWarn = params => {
  return request({
    url: '/api/teachingWarn/getTeacherNotLoginWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//学生行为预警——列表
export const teachingWarngetStudentWarning = params => {
  return request({
    url: '/api/teachingWarn/getStudentWarning',
    method: 'post',
    data: qs.stringify(params)
  })
}
//学生行为预警——列表详情
export const teachingWarngetStudentWarningList = params => {
  return request({
    url: '/api/teachingWarn/getStudentWarningList',
    method: 'post',
    data: qs.stringify(params)
  })
}
//课程质量分析——课程列表
export const teachingWarngetCourseQualityCompleteAvgWarn = params => {
  return request({
    url: '/api/teachingWarn/getCourseQualityCompleteAvgWarn',
    method: 'post',
    data: qs.stringify(params)
  })
}
//课程质量分析——课程列表详情
export const teachingWarngetCourseQualityCompleteAvgWarnList = params => {
  return request({
    url: '/api/teachingWarn/getCourseQualityCompleteAvgWarnList',
    method: 'post',
    data: qs.stringify(params)
  })
}
