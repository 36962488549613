// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 用户来源分布
// /dataUser/getUserData
export const getUserData = params => {
  return request({
    url: '/api/dataUser/getUserData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户省份来源分布
// /dataUser/getUserDataProvince
export const getUserDataProvince = params => {
  return request({
    url: '/api/dataUser/getUserDataProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户校园分布（柱状图）
// /dataUser/getSchoolDistribution
export const getSchoolDistribution = params => {
  return request({
    url: '/api/dataUser/getSchoolDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 用户校园分布（列表）
// /dataUser/getSchoolUserList
export const getSchoolUserList = params => {
  return request({
    url: '/api/dataUser/getSchoolUserList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 导出课程统计（学校课程统计列表）
// /dataUser/exportSchoolUserList
export const exportSchoolUserList = params => {
  return request({
    url: '/api/dataUser/exportSchoolUserList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

export const exportUserDataProvince = params => {
  return request({
    url: '/api/dataUser/exportUserDataProvince',
    responseType: 'blob',
    method: 'post',
    data: qs.stringify(params)
  })
}

export const exportProvinceUserDistribution = params => {
  return request({
    url: '/api/opeDataUser/exportProvinceUserDistribution',
    responseType: 'blob',
    method: 'post',
    data: qs.stringify(params)
  })
} 


