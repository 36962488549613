import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import index from '../views/index.vue'
import schoolHome from '../views/school/home'
import provinceHome from '../views/province/home'
import operationHome from '../views/operation/home'
import authorityHome from '../views/authority/home'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/homePage',
    children: [
      {
        path: '/homePage',
        meta: {
          roles: ['*'],
          title: '数据中心首页'
        },
        name: 'homePage',
        component: () => import('../views/homePage')
      },
      {
        path: '/Login',
        name: 'Login',
        meta: {
          roles: ['*'],
          title: '数据中心登录'
        },
        component: index
      },

      {
        path: '/Overview',
        meta: {
          roles: ['*'],
          title: '数据总览'
        },
        name: 'OverView',
        component: () => import('../views/Overview')
      },
      {
        path: '/realTime',
        meta: {
          roles: ['*'],
          title: '实时动态'
        },
        name: 'RealTime',
        component: () => import('../views/realTime')
      },
      {
        path: '/realTime_testWebsocket',
        meta: {
          roles: ['*'],
          title: '实时动态'
        },
        name: 'RealTime',
        component: () => import('../views/realTime_copy')
      },
      {
        path: '/rankingList',
        meta: {
          roles: ['*'],
          title: '排行榜'
        },
        name: 'RankingList',
        component: () => import('../views/rankingList')
      },
      {
        path: '/userDistribution',
        meta: {
          roles: ['*'],
          title: '全国用户分布'
        },
        name: 'UserDistribution',
        component: () => import('../views/userDistribution')
      },
      {
        path: '/dataAsset',
        meta: {
          roles: ['*'],
          title: '数据资产',
          name: 'dataAsset',
        },
        component: () => import('../views/dataAsset')
      },
      {
        path: '/dataAsset2',
        meta: {
          roles: ['*'],
          title: '数据资产',
          name: 'dataAsset',
        },
        component: () => import('../views/dataAsset2')
      },
      {
        path: '/editNewMedia',
        meta: {
          roles: ['*'],
          title: '编辑新媒体',
          name: 'editNewMedia'
        },
        component: () => import('../views/editNewMedia')
      },
      {
        path: '/screen-test',
        meta: {
          roles: ['*'],
          title: '大屏',
          name: 'screen-test'
        },
        component: () => import('../views/china-map/index.vue')
      },
      {
        path: '/screen-test2',
        meta: {
          roles: ['*'],
          title: '大屏',
          name: 'screen-test2'
        },
        component: () => import('../views/china-map/index2.vue')
      },
      {
        path: '/screen-dev',
        meta: {
          roles: ['*'],
          title: '大屏',
          name: 'screen-dev'
        },
        component: () => import('../views/china-map/index3.vue')
      },
      {
        path: '/dynamic',
        meta: {
          roles: ['*'],
          title: '实时动态大屏',
          name: 'dynamic'
        },
        component: () => import('../views/dynamic.vue')
      },
      {
        path: '/TeacherPortrait',
        meta: {
          roles: ['*'],
          title: '教师画像',
          name: 'TeacherProtrait'
        },
        component: () => import('../views/portrayal/TeacherPortarit.vue')
      },
      {
        path: '/StudentPortrait',
        meta: {
          roles: ['*'],
          title: '学生画像',
          name: 'StudentPortrait'
        },
        component: () => import('../views/portrayal/StudentPortarit.vue')
      },
      {
        path: '/province_dialog',
        meta: {
          roles: ['*'],
          title: '院校列表',
          name: 'province_dialog'
        },
        component: () => import('../views/province_dialog.vue')
      },
      {
        path: '/teaStu_dialog',
        meta: {
          roles: ['*'],
          title: '教师学生列表',
          name: 'teaStu_dialog'
        },
        component: () => import('../views/teaStu_dialog.vue')
      },
      {
        path: '/zykCourseData',
        meta: {
          roles: ['*'],
          title: '资源库课程数据',
          name: 'TeacherStatistics'
        },
        component: () => import('../views/portrayal/zykCourseData.vue')
      },
      {
        path: '/SPOCCourseData',
        meta: {
          roles: ['*'],
          title: 'SPOC课程数据',
          name: 'SPOCCourseData'
        },
        component: () => import('../views/portrayal/SPOCCourseData.vue')
      },
      {
        path: '/ClassroomTeaching',
        meta: {
          roles: ['*'],
          title: '课堂与教学数据',
          name: 'ClassroomTeaching'
        },
        component: () => import('../views/portrayal/ClassroomTeaching.vue')
      },
      {
        path: '/ClassroomTeachingClass',
        meta: {
          roles: ['*'],
          title: '课堂教学班级数据',
          name: 'ClassroomTeachingClass'
        },
        component: () => import('../views/portrayal/ClassroomTeachingClass.vue')
      },
      {
        path: '/MOOCCourseData',
        meta: {
          roles: ['*'],
          title: 'MOOC学院课程数据',
          name: 'MOOCCourseData'
        },
        component: () => import('../views/portrayal/MOOCCourseData.vue')
      },
    ]
  },
  {
    path: '/401',
    meta: {
      roles: ['*'],
      title: '401'
    },
    name: '401',
    component: () => import('../views/401')
  },

  /* 校级 */
  {
    path: '/school',
    meta: {
      roles: ['*'], title: '校级'
    },
    component: schoolHome,
    children: [
      {
        path: '/school',
        redirect: '/school/dashBoard'
      },
      /* 数据总览 */
      {
        path: '/school/dashBoard',
        meta: {
          roles: ['*'],
          title: '总体数据'
        },
        name: 'dashBoard',
        component: () => import('../views/school/dashBoard')
      },
      /* 实时大屏 */
      {
        path: '/school/RealtimeLargeScreen',
        meta: {
          roles: ['*'],
          title: '实时大屏'
        },
        name: 'RealtimeLargeScreen',
        component: () => import('../views/school/RealtimeLargeScreen')
      },
      {
        path: '/school/effect',
        meta: {
          roles: ['*'],
          title: '影响力'
        },
        name: 'Effect',
        component: () => import('../views/school/dashBoard/effect')
      },
      {
        path: '/school/DistributionDetails',
        meta: {
          roles: ['*'],
          title: '用户校园分布详情',
          activePath: '/school/effect'
        },
        name: 'DistributionDetails',
        component: () => import('../views/school/dashBoard/DistributionDetails')
      },
      /* 数据总览 */
      // 师生信息
      {
        path: '/school/TeacherStatistics',
        meta: {
          roles: ['*'],
          title: '教师统计'
        },
        name: 'TeacherStatistics',
        component: () => import('../views/school/TeacherStudent/TeacherStatistics')
      },
      {
        path: '/school/StudentStatistics',
        meta: {
          roles: ['*'],
          title: '学生统计'
        },
        name: 'StudentStatistics',
        component: () => import('../views/school/TeacherStudent/StudentStatistics')
      },
      {
        path: '/school/TeacherPortrait',
        meta: {
          roles: ['*'],
          title: '教师画像',
          activePath: '/school/TeacherStatistics'
        },
        name: 'TeacherPortrait',
        component: () => import('../views/school/TeacherStudent/TeacherPortrait')
      },
      {
        path: '/school/StudentPortrait',
        meta: {
          roles: ['*'],
          title: '学生画像',
          activePath: '/school/StudentStatistics'
        },
        name: 'StudentPortrait',
        component: () => import('../views/school/TeacherStudent/StudentPortrait')
      },
      {
        path: '/school/zykCourseData',
        meta: {
          roles: ['*'],
          title: '资源库课程数据',
          activePath: '/school/TeacherStatistics'
        },
        name: 'zykCourseData',
        component: () => import('../views/school/TeacherStudent/zykCourseData')
      },
      {
        path: '/school/SPOCCourseData',
        meta: {
          roles: ['*'],
          title: 'SPOC课程数据',
          activePath: '/school/TeacherStatistics'
        },
        name: 'SPOCCourseData',
        component: () => import('../views/school/TeacherStudent/SPOCCourseData')
      },
      {
        path: '/school/ClassroomTeaching',
        meta: {
          roles: ['*'],
          title: '课堂与教学数据',
          activePath: '/school/TeacherStatistics'
        },
        name: 'ClassroomTeaching',
        component: () => import('../views/school/TeacherStudent/ClassroomTeaching')
      },
      {
        path: '/school/ClassroomTeachingClass',
        meta: {
          roles: ['*'],
          title: '课堂教学班级数据',
          activePath: '/school/TeacherStatistics'
        },
        name: 'ClassroomTeachingClass',
        component: () => import('../views/school/TeacherStudent/ClassroomTeachingClass')
      },
      {
        path: '/school/MOOCCourseData',
        meta: {
          roles: ['*'],
          title: 'MOOC学院课程数据',
          activePath: '/school/TeacherStatistics'
        },
        name: 'MOOCCourseData',
        component: () => import('../views/school/TeacherStudent/MOOCCourseData')
      },
      // 院系统计
      {
        path: '/school/CollegeStatistics',
        meta: {
          roles: ['*'],
          title: '院系统计'
        },
        name: 'CollegeStatistics',
        component: () => import('../views/school/CollegeStatistics')
      },
      // 资源库
      {
        path: '/school/ProfessionalConstruction',
        meta: {
          roles: ['*'],
          title: '专业建设'
        },
        name: 'ProfessionalConstruction',
        component: () => import('../views/school/zykStatistics/ProfessionalConstruction')
      },
      {
        path: '/school/teacherResourceConstruction',
        meta: {
          roles: ['*'],
          title: '教师资源建设详情',
          activePath: '/school/ProfessionalConstruction'
        },
        name: 'teacherResourceConstruction',
        component: () => import('../views/school/zykStatistics/teacherResourceConstruction')
      },
      {
        path: '/school/ResourceConstruction',
        meta: {
          roles: ['*'],
          title: '资源建设'
        },
        name: 'ResourceConstruction',
        component: () => import('../views/school/zykStatistics/ResourceConstruction')
      },
      {
        path: '/school/ResourceConstructionDetails',
        meta: {
          roles: ['*'],
          title: '资源建设详情',
          activePath: '/school/ResourceConstruction'
        },
        name: 'ResourceConstructionDetails',
        component: () => import('../views/school/zykStatistics/ResourceConstructionDetails')
      },
      {
        path: '/school/CurriculumConstruction',
        meta: {
          roles: ['*'],
          title: '课程建设'
        },
        name: 'CurriculumConstruction',
        component: () => import('../views/school/zykStatistics/CurriculumConstruction')
      },
      {
        path: '/school/zykCourseDetails',
        meta: {
          roles: ['*'],
          title: '课程详情',
          activePath: '/school/CurriculumConstruction'
        },
        name: 'zykCourseDetails',
        component: () => import('../views/school/zykStatistics/zykCourseDetails')
      },
      {
        path: '/school/zykRankingList',
        meta: {
          roles: ['*'],
          title: '排行榜'
        },
        name: 'zykRankingList',
        component: () => import('../views/school/zykStatistics/zykRankingList')
      },
      {
        path: '/school/CourseCall',
        meta: {
          roles: ['*'],
          title: '课程调用'
        },
        name: 'CourseCall',
        component: () => import('../views/school/zykStatistics/CourseCall')
      },
      // 职教云
      {
        path: '/school/zjyData',
        meta: {
          roles: ['*'],
          title: '基本数据'
        },
        name: 'zjyData',
        component: () => import('../views/school/zjyStatistics/zjyData')
      },
      {
        path: '/school/zjyBehaviorAnalysis',
        meta: {
          roles: ['*'],
          title: '行为分析'
        },
        name: 'zjyBehaviorAnalysis',
        component: () => import('../views/school/zjyStatistics/zjyBehaviorAnalysis')
      },
      {
        path: '/school/AchievementStatistics',
        meta: {
          roles: ['*'],
          title: '成绩统计'
        },
        name: 'AchievementStatistics',
        component: () => import('../views/school/zjyStatistics/AchievementStatistics')
      },
      //排行榜
      {
        path: '/school/zjyRankingList',
        component: () => import('../views/school/zjyStatistics/zjyRankingList/index'),
        redirect: '/school/courseRanking',
        children: [
          {
            path: '/school/courseRanking',
            meta: {
              roles: ['*'],
              title: '活跃课程排行榜'
            },
            name: 'courseRanking',
            component: () => import('../views/school/zjyStatistics/zjyRankingList/courseRanking')
          },
          {
            path: '/school/joinRanking',
            meta: {
              roles: ['*'],
              title: '班级出勤排行榜'
            },
            name: 'joinRanking',
            component: () => import('../views/school/zjyStatistics/zjyRankingList/joinRanking')
          },
          {
            path: '/school/teacherRanking',
            meta: {
              roles: ['*'],
              title: '活跃教师排行榜'
            },
            name: 'teacherRanking',
            component: () => import('../views/school/zjyStatistics/zjyRankingList/teacherRanking')
          },
          {
            path: '/school/studentRanking',
            meta: {
              roles: ['*'],
              title: '活跃学生排行榜'
            },
            name: 'studentRanking',
            component: () => import('../views/school/zjyStatistics/zjyRankingList/studentRanking')
          },
        ]
      },
      {
        path: '/school/yxStatistics',
        meta: {
          roles: ['*'],
          title: '院系统计'
        },
        name: 'yxStatistics',
        component: () => import('../views/school/zjyStatistics/yxStatistics')
      },
      {
        path: '/school/classteachStatisticsdetail',
        meta: {
          roles: ['*'],
          title: '授课班级详情'
        },
        name: 'classteachStatisticsdetail',
        component: () => import('../views/school/zjyStatistics/classteachStatisticsdetail')
      },
      {
        path: '/school/classteachStatisticsteachdetail',
        meta: {
          roles: ['*'],
          title: '授课班级课堂教学详情'
        },
        name: 'classteachStatisticsteachdetail',
        component: () => import('../views/school/zjyStatistics/classteachStatisticsteachdetail')
      },
      //课堂报告--多页面
      {
        path: '/school/classteachStatistics/index',
        component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/index'),
        redirect: '/school/classteachStatisticsreport',
        children: [
          {
            path: '/school/classteachStatisticsreport',
            meta: {
              roles: ['*'],
              title: '课堂统计数据'
            },
            name: 'classteachStatisticsreport',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsreport')
          },
          {
            path: '/school/classteachStatisticsphb',
            meta: {
              roles: ['*'],
              title: '课堂学习排行榜'
            },
            name: 'classteachStatisticsphb',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsphb')
          },
          {
            path: '/school/classteachStatisticsjoinact',
            meta: {
              roles: ['*'],
              title: '课堂活动参与'
            },
            name: 'classteachStatisticsjoinact',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsjoinact')
          },
          {
            path: '/school/classteachStatisticsjoinbefore',
            meta: {
              roles: ['*'],
              title: '课堂活动参与课前'
            },
            name: 'classteachStatisticsjoinbefore',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsjoinbefore')
          },
          {
            path: '/school/classteachStatisticsjoinmid',
            meta: {
              roles: ['*'],
              title: '课堂活动参与课中'
            },
            name: 'classteachStatisticsjoinmid',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsjoinmid')
          },
          {
            path: '/school/classteachStatisticsjoinlater',
            meta: {
              roles: ['*'],
              title: '课堂活动参与课后'
            },
            name: 'classteachStatisticsjoinlater',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsjoinlater')
          },
          {
            path: '/school/classteachStatisticsstupj',
            meta: {
              roles: ['*'],
              title: '学生评价'
            },
            name: 'classteachStatisticsstupj',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsstupj')
          },
          {
            path: '/school/classteachStatisticsstugk',
            meta: {
              roles: ['*'],
              title: '学生概况'
            },
            name: 'classteachStatisticsstugk',
            component: () => import('../views/school/zjyStatistics/classteachStatisticsreport/classteachStatisticsstugk')
          },

        ]
      },
      {
        path: '/school/classteachStatistics',
        meta: {
          roles: ['*'],
          title: '课堂教学统计'
        },
        name: 'classteachStatistics',
        component: () => import('../views/school/zjyStatistics/classteachStatistics')
      },
      {
        path: '/school/CCStatistics',
        meta: {
          roles: ['*'],
          title: '课程和班级统计'
        },
        name: 'CCStatistics',
        component: () => import('../views/school/zjyStatistics/CCStatistics')
      },
      {
        path: '/school/zjyCourseDetails',
        meta: {
          roles: ['*'],
          title: '课程详情',
          activePath: '/school/CCStatistics'
        },
        name: 'zjyCourseDetails',
        component: () => import('../views/school/zjyStatistics/zjyCourseDetails')
      },
      {
        path: '/school/zjyClasseDetails',
        meta: {
          roles: ['*'],
          title: '班级详情',
          activePath: '/school/CCStatistics'
        },
        name: 'zjyClasseDetails',
        component: () => import('../views/school/zjyStatistics/zjyClasseDetails')
      },
      {
        path: '/school/zjyStudentDetails',
        meta: {
          roles: ['*'],
          title: '学生详情',
          activePath: '/school/CCStatistics'
        },
        name: 'zjyStudentDetails',
        component: () => import('../views/school/TeacherStudent/StudentPortrait')
        // component: () => import('../views/school/zjyStatistics/zjyStudentDetails')
      },
      // MOOC
      {
        path: '/school/MOOCData',
        meta: {
          roles: ['*'],
          title: '基本数据'
        },
        name: 'MOOCData',
        component: () => import('../views/school/MOOCStatistics/MOOCData')
      },
      {
        path: '/school/MOOCRankingList',
        meta: {
          roles: ['*'],
          title: '排行榜'
        },
        name: 'MOOCRankingList',
        component: () => import('../views/school/MOOCStatistics/MOOCRankingList')
      },
      {
        path: '/school/MOOCCourseStatistics',
        meta: {
          roles: ['*'],
          title: '课程统计'
        },
        name: 'MOOCCourseStatistics',
        component: () => import('../views/school/MOOCStatistics/MOOCCourseStatistics')
      },
      {
        path: '/school/MOOCCourseDetails',
        meta: {
          roles: ['*'],
          title: '课程详情',
          activePath: '/school/MOOCCourseStatistics'
        },
        name: 'MOOCCourseDetails',
        component: () => import('../views/school/MOOCStatistics/MOOCCourseDetails')
      },
      // 专业群
      {
        path: '/school/zyqData',
        meta: {
          roles: ['*'],
          title: '数据预览'
        },
        name: 'zyqData',
        component: () => import('../views/school/zyqStatistics/zyqData')
      },
      {
        path: '/school/ResourcesStatistics',
        meta: {
          roles: ['*'],
          title: '资源统计'
        },
        name: 'ResourcesStatistics',
        component: () => import('../views/school/zyqStatistics/ResourcesStatistics')
      },
      {
        path: '/school/BankStatistics',
        meta: {
          roles: ['*'],
          title: '题库统计'
        },
        name: 'BankStatistics',
        component: () => import('../views/school/zyqStatistics/BankStatistics')
      },
      {
        path: '/school/zyqCourseStatistics',
        meta: {
          roles: ['*'],
          title: '课程统计'
        },
        name: 'zyqCourseStatistics',
        component: () => import('../views/school/zyqStatistics/CourseStatistics')
      },
      {
        path: '/school/zyqUserDetails',
        meta: {
          roles: ['*'],
          title: '用户详情'
        },
        name: 'zyqUserDetails',
        component: () => import('../views/school/zyqStatistics/UserDetails')
      },
      {
        path: '/school/zyqLogStatistics',
        meta: {
          roles: ['*'],
          title: '日志统计'
        },
        name: 'zyqLogStatistics',
        component: () => import('../views/school/zyqStatistics/LogStatistics')
      },
      {
        path: '/school/UserBehaviorRecord',
        meta: {
          roles: ['*'],
          title: '日志统计-用户行为记录',
          activePath: '/school/zyqLogStatistics'
        },
        name: 'UserBehaviorRecord',
        component: () => import('../views/school/zyqStatistics/UserBehaviorRecord')
      },
      {
        path: '/school/CourseResourcesStatistics',
        meta: {
          roles: ['*'],
          title: '资源统计-按课程统计',
          activePath: '/school/ResourcesStatistics'
        },
        name: 'CourseResourcesStatistics',
        component: () => import('../views/school/zyqStatistics/CourseResourcesStatistics')
      },
      {
        path: '/school/CourseBankStatistics',
        meta: {
          roles: ['*'],
          title: '题库统计-按课程统计',
          activePath: '/school/BankStatistics'
        },
        name: 'CourseBankStatistics',
        component: () => import('../views/school/zyqStatistics/CourseBankStatistics')
      },
      {
        path: '/school/zyqCourseDetails',
        meta: {
          roles: ['*'],
          title: '课程详情',
          activePath: '/school/zyqCourseStatistics'
        },
        name: 'zyqCourseDetails',
        component: () => import('../views/school/zyqStatistics/CourseDetails')
      },
      {
        path: '/school/zyqUserDetailsList',
        meta: {
          roles: ['*'],
          title: '用户详情-按院校分布列表',
          activePath: '/school/zyqUserDetails'
        },
        name: 'zyqUserDetailsList',
        component: () => import('../views/school/zyqStatistics/UserDetailsList')
      },
      // 预警和排名
      {
        path: '/school/AttendanceWarning',
        meta: {
          roles: ['*'],
          title: '考勤预警'
        },
        name: 'AttendanceWarning',
        component: () => import('../views/school/AlertRanking/AttendanceWarning')
      },
      {
        path: '/school/PerformanceWarning',
        meta: {
          roles: ['*'],
          title: '成绩预警'
        },
        name: 'PerformanceWarning',
        component: () => import('../views/school/AlertRanking/PerformanceWarning')
      },
      {
        path: '/school/TeacherBehaviorWarning',
        meta: {
          roles: ['*'],
          title: '教师行为预警'
        },
        name: 'TeacherBehaviorWarning',
        component: () => import('../views/school/AlertRanking/TeacherBehaviorWarning')
      },
      {
        path: '/school/StudentBehaviorWarning',
        meta: {
          roles: ['*'],
          title: '学生行为预警'
        },
        name: 'StudentBehaviorWarning',
        component: () => import('../views/school/AlertRanking/StudentBehaviorWarning')
      },
      {
        path: '/school/CourseQualityAnalysis',
        meta: {
          roles: ['*'],
          title: '课程质量分析'
        },
        name: 'CourseQualityAnalysis',
        component: () => import('../views/school/AlertRanking/CourseQualityAnalysis')
      },
      {
        path: '/school/CreditCertification',
        meta: {
          roles: ['*'],
          title: '课程质量分析'
        },
        name: 'CreditCertification',
        component: () => import('../views/school/CreditCertification')
      }
    ]
  },
  //省级
  {
    path: '/province',
    meta: {
      roles: ['*']
    },
    component: provinceHome,
    children: [
      {
        path: '/province',
        redirect: '/province/dashBoard'
      },
      {
        path: '/province/dashBoard',
        meta: {
          roles: ['*'],
          title: '数据概览'
        },
        name: 'dashBoard',
        component: () => import('../views/province/dashBoard')
      },
      {
        path: '/province/RankingStatistics',
        meta: {
          roles: ['*'],
          title: '学院综合应用排行榜'
        },
        name: 'RankingStatistics',
        component: () => import('../views/province/RankingStatistics')
      },
      {
        path: '/province/ResourceContribution',
        meta: {
          roles: ['*'],
          title: '资源贡献排行榜'
        },
        name: 'ResourceContribution',
        component: () => import('../views/province/ResourceContribution')
      },
      {
        path: '/province/CollegeStatistics',
        meta: {
          roles: ['*'],
          title: '院校统计'
        },
        name: 'CollegeStatistics',
        component: () => import('../views/province/CollegeStatistics')
      },
      {
        path: '/province/CourseStatistics',
        meta: {
          roles: ['*'],
          title: '课程统计'
        },
        name: 'CourseStatistics',
        component: () => import('../views/province/CourseStatistics')
      },
      {
        path: '/province/UserStatistics',
        meta: {
          roles: ['*'],
          title: '用户统计'
        },
        name: 'UserStatistics',
        component: () => import('../views/province/UserStatistics')
      },
      {
        path: '/province/ProfessionalGroup',
        meta: {
          roles: ['*'],
          title: '专业群数据'
        },
        name: 'ProfessionalGroup',
        component: () => import('../views/province/teachLog/ProfessionalGroup')
      },
      {
        path: '/province/ResourceLibrary',
        meta: {
          roles: ['*'],
          title: '资源库建设'
        },
        name: 'ResourceLibrary',
        component: () => import('../views/province/teachLog/ResourceLibrary')
      },
      {
        path: '/province/OverviewTeaching',
        meta: {
          roles: ['*'],
          title: '课堂教学概况'
        },
        name: 'OverviewTeaching',
        component: () => import('../views/province/teachLog/OverviewTeaching')
      },
      {
        path: '/province/ActivityLogStatistics',
        meta: {
          roles: ['*'],
          title: '活动日志统计'
        },
        name: 'ActivityLogStatistics',
        component: () => import('../views/province/teachLog/ActivityLogStatistics')
      },
      {
        path: '/province/earlyWarning',
        meta: {
          roles: ['*'],
          title: '预警'
        },
        name: 'earlyWarning',
        component: () => import('../views/province/earlyWarning')
      }
    ]
  },
  //运营
  {
    path: '/operation',
    meta: {
      roles: ['*']
    },
    component: operationHome,
    children: [
      {
        path: '/operation',
        redirect: '/operation/dashBoard'
      },
      {
        path: '/operation/dashBoard',
        meta: {
          roles: ['*'],
          title: '数据概览'
        },
        name: 'OdashBoard',
        component: () => import('../views/operation/dashBoard')
      },
      {
        path: '/operation/UserStatistics',
        meta: {
          roles: ['*'],
          title: '用户统计'
        },
        name: 'OUserStatistics',
        component: () => import('../views/operation/UserStatistics')
      },
      {
        path: '/operation/MaterialStatistics',
        meta: {
          roles: ['*'],
          title: '素材统计'
        },
        name: 'OMaterialStatistics',
        component: () => import('../views/operation/MaterialStatistics')
      },
      {
        path: '/operation/QuestionStatistics',
        meta: {
          roles: ['*'],
          title: '题库统计'
        },
        name: 'OQuestionStatistics',
        component: () => import('../views/operation/QuestionStatistics')
      },
      {
        path: '/operation/CourseStatistics',
        meta: {
          roles: ['*'],
          title: '课程统计'
        },
        name: 'OCourseStatistics',
        component: () => import('../views/operation/CourseStatistics')
      },
      {
        path: '/operation/ResourceCall',
        meta: {
          roles: ['*'],
          title: '资源调用'
        },
        name: 'OResourceCall',
        component: () => import('../views/operation/ResourceCall')
      },
      {
        path: '/operation/NewDetails',
        meta: {
          roles: ['*'],
          title: '新增详情'
        },
        name: 'ONewDetails',
        component: () => import('../views/operation/NewDetails')
      },
      {
        path: '/operation/ActivityLogStatistics',
        meta: {
          roles: ['*'],
          title: '日志统计'
        },
        name: 'OActivityLogStatistics',
        component: () => import('../views/operation/ActivityLogStatistics')
      }
    ]
  },
  // 权限设置
  // {
  //   path: '/authority',
  //   meta: {
  //     roles: ['*']
  //   },
  //   component: authorityHome,
  //   children: [
  //     {
  //       path: '/authority',
  //       redirect: '/authority/roleAdmin'
  //     },
  //     {
  //       path: '/authority/roleAdmin',
  //       meta: {
  //         roles: ['*'],
  //         title: '角色管理'
  //       },
  //       name: 'roleAdmin',
  //       component: () => import('../views/authority/roleAdmin')
  //     },
  //     {
  //       path: '/authority/userAdmin',
  //       meta: {
  //         roles: ['*'],
  //         title: '用户管理'
  //       },
  //       name: 'userAdmin',
  //       component: () => import('../views/authority/userAdmin')
  //     },
  //     {
  //       path: '/authority/schoolAdmin',
  //       meta: {
  //         roles: ['*'],
  //         title: '校级数据中心管理'
  //       },
  //       name: 'schoolAdmin',
  //       component: () => import('../views/authority/schoolAdmin')
  //     },
  //   ]
  // },
]
const router = new VueRouter({
  mode: 'history',
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
