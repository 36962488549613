<template>
  <div>
    <el-container>
      <el-header>
        <common-head @mainKey="mainKey" />
      </el-header>
      <el-container>
        <el-aside>
          <common-aside />
        </el-aside>
        <el-main :key="mainNumber">
          <!-- <nav-tabs /> -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonAside from "./components/CommonAside.vue";
import CommonHead from "./components/CommonHead";
// import NavTabs from "./components/NavTabs.vue";
export default {
  name: "LayOut",
  components: {
    CommonAside,
    CommonHead,
    // NavTabs,
  },
  data() {
    return {
      mainNumber: 1,
    };
  },
  methods: {
    mainKey() {
      this.mainNumber++;
    },
  },
  created() {},
  beforeCreate() {},
};
</script>
