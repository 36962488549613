// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'


// 第二屏--用户活跃情况
export const screenGetUserActiveDetail = () => {
    return request({
        url: '/api/bigLargeScreen/getUserActiveDetail',
        method: 'post',
    })
}

// 第二屏--用户覆盖
export const screenGetLogAllNum = () => {
    return request({
        url: '/api/bigLargeScreen/getLogAllNum',
        method: 'post',
    })
}

// 第二屏--总日志量
export const screenGetStuSchDistribution = () => {
    return request({
        url: '/api/bigLargeScreen/getStuSchDistribution',
        method: 'post',
    })
}

// 第二屏--数据增长曲线图
export const screenGetDataGrowthCurve = () => {
    return request({
        url: '/api/bigLargeScreen/getDataGrowthCurve',
        method: 'post',
    })
}

// 获取数据概览
export const screenGetOverview = () => {
    return request({
        url: '/api/bigLargeScreen/getOverview',
        method: 'post',
    })
}

// 第三屏1——师生活动日志-课程活动偏好
export const screenGetTchrStudentActiveLog = () => {
    return request({
        url: '/api/bigLargeScreen/getTchrStudentActiveLog',
        method: 'post',
    })
}


// 第三屏1——活跃学校Top10-活跃课程Top10-活跃教师Top10
export const screengetActiveSchoolCourseTchr = () => {
    return request({
        url: '/api/bigLargeScreen/getActiveSchoolCourseTchr',
        method: 'post',
    })
}



// 第三屏2——各级资源库专业大类分布情况-各级资源库省份分布情况
export const screengetResourcesForMajor = () => {
    return request({
        url: '/api/bigLargeScreen/getResourcesForMajor',
        method: 'post',
    })
}

// 第三屏2——主持资源库建设的学校-机构列表
export const screengetIcveBuildSchool = () => {
    return request({
        url: '/api/bigLargeScreen/getIcveBuildSchool',
        method: 'post',
    })
}

// 第三屏2——各个图表
export const screengetResourceFenBu = () => {
    return request({
        url: '/api/bigLargeScreen/getResourceFenBu',
        method: 'post',
    })
}

// 第三屏3——MOOC活跃情况-课程增长曲线
export const screengetMoocActiveGrowthCurve = () => {
    return request({
        url: '/api/bigLargeScreen/getMoocActiveGrowthCurve',
        method: 'post',
    })
}

// 第三屏3——最受欢迎课程Top10-学校贡献排行榜Top10
export const screengetLikeCourseSchool = () => {
    return request({
        url: '/api/bigLargeScreen/getLikeCourseSchool',
        method: 'post',
    })
}

// 第五屏--智慧职教数据资产(left)
export const screengetWafStatistics = () => {
    return request({
        url: '/api/bigLargeScreen/getWafStatistics',
        method: 'post',
    })
}

// 第五屏——获取华为云流量趋势
export const screengetHuaweiLiuLiang = () => {
    return request({
        url: '/api/bigLargeScreen/getHuaweiLiuLiang',
        method: 'post',
    })
}

// 第五屏——华为云访客区域指标详情
export const screengetHuaweiStatistics = (params) => {
    return request({
        url: '/api/bigLargeScreen/getHuaweiStatistics',
        method: 'post',
        data: qs.stringify(params)
    })
}


// 获取实时动态接口
export const screengetRealTime = (params) => {
    return request({
        url: '/api/bigLargeScreen/getRealTime',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 实时大屏
export const getTodayActiveCourse = () => {
    return request({
        url: 'api/bigLargeScreen/getTodayActiveCourse',
        method: 'post'
    })
}

// 实时动态——今日活跃教师-学生
export const getTodayActiveTeacher = (params) => {
    return request({
        url: '/api/bigLargeScreen/getTodayActiveTeacher',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 实时动态——日活数量曲线
export const getUserLogActiveList = (params) => {
    return request({
        url: '/api/bigLargeScreen/getUserLogActiveList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 地图用户量和日志接口
export const getProvinceLogUser = () => {
    return request({
        url: 'api/bigLargeScreen/getProvinceLogUser',
        method: 'post'
    })
}

// 实时动态-今日活跃课堂
export const getTodayActivity = () => {
    return request({
        url: 'api/bigLargeScreen/getTodayActivity',
        method: 'post'
    })
}

// 实时动态-今日活跃学校
export const getTodayActiveSchool = () => {
    return request({
        url: 'api/bigLargeScreen/getTodayActiveSchool',
        method: 'post'
    })
}


// 国际化课程
export const queryCourseResourceNum = () => {
    return request({
        url: 'gjpd/open/interface/queryCourseResourceNum',
        method: 'post'
    })
}

// 智能工具
export const queryVideoNum = () => {
    return request({
        url: 'api/dataStatistics/getDiSanFang',
        method: 'post',
    })
}

// 维护新媒体接口
export const editNewMedia = (params) => {
    return request({
        url: 'api/webApiController/updateIntelligence',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 根据省份获取院校列表
export const getSchoolListByProvince = (params) => {
    return request({
        url: 'api/teaPortrait/getSchoolListByProvince',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 根据院校获取师生列表
export const getUserListBySchoolId = (params) => {
    return request({
        url: 'api/teaPortrait/getUserListBySchoolId',
        method: 'post',
        data: qs.stringify(params)
    })
}
