<template>
  <div class="dpcontent">
    <header>
      <div class="title">智慧职教数据中心</div>
      <div class="source">
        <span
          v-for="(item, index) in tabData"
          @click="listPush(item.path)"
          :key="index"
          >{{ item.name }}</span
        >
      </div>
    </header>
    <section>
      <div class="middle">
        <div class="banner">
          <div class="sjimg">
            <img
              class="imgqq"
              src="@/assets/index/img (11).png"
              alt=""
              ref="imgqq"
            />
            <div class="imgtitle">数据中心</div>
            <div class="zyk">
              <img src="@/assets/index/img (23).png" alt="" />
            </div>
            <div class="zjy">
              <img src="@/assets/index/img (21).png" alt="" />
            </div>
            <div class="xxt">
              <img src="@/assets/index/img (29).png" alt="" />
            </div>
            <div class="mooc">
              <img src="@/assets/index/img (25).png" alt="" />
            </div>
            <div class="gjs">
              <img src="@/assets/index/img (27).png" alt="" />
            </div>
          </div>
        </div>
        <div class="login">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>账号登录</span>
            </div>
            <div>
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="0px"
                class="ruleForm"
              >
                <el-form-item class="input" label="" prop="userName">
                  <img src="@/assets/index/img (32).png" alt="" />
                  <el-input
                    v-model="ruleForm.userName"
                    placeholder="请输入账号"
                  ></el-input>
                </el-form-item>
                <el-form-item class="input" label="" prop="password">
                  <img src="@/assets/index/img (1).png" alt="" />
                  <el-input
                    v-model="ruleForm.password"
                    placeholder="请输入密码"
                    show-password
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="pwd">
                    <el-checkbox v-model="passWordShow">记住密码</el-checkbox>
                    <!-- <span>忘记密码<i class="el-icon-question"></i></span> -->
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button
                    class="loginbtn"
                    type="primary"
                    @click="submitForm('ruleForm')"
                    >登录</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
      </div>
      <!-- <div class="footer">
        <div class="bg">
          <div class="tabTitle" v-for="(item, index) in tabTitle" :key="index" @click="listPush(item.path)">
            <img :src="item.icon" alt="">
            <div class="zhez">
              <h5>{{item.name}}</h5>
            </div>
          </div>
        </div>
      </div> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import 'amfe-flexible/index.js'
// import { tokenLogin } from '@/api/modules/Prologin.js'
import { setToken } from "@/utils/auth";
import Cookies from "js-cookie";
export default {
  name: "LoginIn",
  components: {},
  data() {
    return {
      tabData: [
        {
          name: "资源库",
          path: "https://www.icve.com.cn/",
        },
        {
          name: "职教云",
          path: "https://zjy2.icve.com.cn/",
        },
        {
          name: "MOOC",
          path: "https://mooc.icve.com.cn/",
        },
      ],
      tabTitle: [
        {
          name: "数据总览",
          path: "/Overview",
          icon: require("@/assets/index/img (19).png"),
        },
        {
          name: "实时动态",
          path: "/realTime",
          icon: require("@/assets/index/img (16).png"),
        },
        {
          name: "排行榜",
          path: "/rankingList",
          icon: require("@/assets/index/img (17).png"),
        },
        {
          name: "全国用户分布",
          path: "/userDistribution",
          icon: require("@/assets/index/img (18).png"),
        },
      ],
      qqlist: ["zyk", "zjy", "xxt", "mooc", "gjs"],
      ruleForm: {
        password: "",
        userName: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      passWordShow: false, // 记住密码
    };
  },
  methods: {
    async getApiToken() {
      let token = this.$route.query.token;
      sessionStorage.setItem("dataSource", this.$route.query.dataSource);
      if (token) {
        try {
          const { data: res } = await this.$api.tokenLogin({ token: token,dataSource:this.$route.query.dataSource });
          console.log(res, "测试");
          if (res.code === 2000) {
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            this.$router.push("/homePage");
          } else {
            // this.$message.error(res.msg)
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async submitForm(formName) {
      try {
        const { data: res } = await this.$api.goLogin(this.ruleForm);
        if (res.code === 2000) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          if (this.passWordShow == true) {
            let passWordList = this.ruleForm;
            passWordList.passWordShow = true;
            localStorage.setItem("userPassWord", JSON.stringify(passWordList));
          } else {
            localStorage.removeItem("userPassWord");
          }
          setToken(Cookies.get("dc-token"));
          if(res.data.roleLevel == 3) {
            this.$router.push('/school')
          } else {
            this.$router.push("/homePage");
          }
          
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
      // console.log(res,'llldf')
    },

    async getRouter(userId) {
      try {
        const { data: res } = await this.$api.queryUserRight({
          userId: userId,
        });
        if (res.code === 2000) {
          // this.$router.push('/homePage')
          // this.getRouter()
        } else {
          // this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    listPush(path) {
      if (path.indexOf("https://") > -1) {
        window.open(path);
      } else {
        const routeUrl = this.$router.resolve({ path });
        window.open(routeUrl.href, "_blank");
      }
    },
  },
  mounted() {
    if (this.$route.query.token) {
      this.getApiToken();
    }
    // console.log(this.$refs.imgqq.style)
    let passWordList = JSON.parse(localStorage.getItem("userPassWord"));
    console.log(passWordList);
    if (passWordList) {
      this.ruleForm.password = passWordList.password;
      this.ruleForm.userName = passWordList.userName;
      this.passWordShow = passWordList.passWordShow;
    }
  },
};
</script>

<style lang="scss" scoped>
$imgWdith: 489px;

@keyframes tiaod {
  0% {
    transform: translateY(800px);
  }

  50% {
    transform: translateY(40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes xuanz {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

header {
  .title {
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 40px;
    margin: 0 30px;
  }

  .source {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 25px;

    span {
      margin-left: 100px;
    }
  }
}

section {
  flex: 1;
  display: flex;
  flex-direction: column;

  .middle {
    flex: 1;
    width: 90vw;
    // margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .banner {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      .sjimg {
        position: relative;

        .imgqq {
          width: $imgWdith;
          // transform: rotate(30deg);
          // transition: transform 20s;
          // transition-timing-function: linear;
          // -webkit-transition-timing-function: linear;
          // -webkit-transition: transform 20s;
          animation-name: xuanz;
          animation-duration: 20s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .imgtitle {
          position: absolute;
          top: 0;
          left: 0;
          text-align: center;
          width: 100%;
          height: 100%;
          font-size: 38px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: $imgWdith;
        }

        .zyk {
          position: absolute;
          top: -117px;
          left: 72px;

          img {
            width: 87px;
          }

          animation-name: tiaod;
          animation-duration: 8s;
          // animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .zjy {
          position: absolute;
          top: 64px;
          left: -103px;

          img {
            width: 73px;
          }

          animation-name: tiaod;
          animation-duration: 8s;
          // animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .xxt {
          position: absolute;
          bottom: -85px;
          left: -85px;

          img {
            width: 111px;
          }

          animation-duration: 9s;
          // animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        .mooc {
          position: absolute;
          top: -17px;
          right: -94px;

          img {
            width: 111px;
          }

          animation-name: tiaod;
          animation-duration: 11s;
          // animation-iteration-count: infinite;
          animation-timing-function: ease;
        }

        .gjs {
          position: absolute;
          bottom: -96px;
          right: -60px;

          img {
            width: 111px;
          }

          animation-name: tiaod;
          animation-duration: 8s;
          // animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }
      }
    }

    .login {
      width: 400px;
      height: 500px;

      // background: rgba(24, 122, 205, 0.3);
      // border: 2px solid #187ACD;
      .box-card {
        height: 500px;
        background: rgba(24, 122, 205, 0.1);
        border: 2px solid #187acd;

        .input {
          border-bottom: 1px solid #fff;
          padding-top: 30px;

          img {
            width: 24px;
            height: 24px;
            margin-left: 10px;
          }
        }

        .pwd {
          display: flex;
          justify-content: space-between;
          color: #fff;
          height: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;

          span:hover {
            color: #409eff;
          }
        }

        .loginbtn {
          width: 100%;
          margin-top: 40px;
        }
      }
    }
  }

  .footer {
    width: 100%;

    .bg {
      margin: 0 auto;
      width: 80vw;
      display: flex;
      justify-content: space-between;
      height: 170px;
      margin-bottom: 50px;

      .tabTitle {
        width: 288px;
        height: 170px;
        position: relative;
        cursor: pointer;

        img {
          width: 288px;
          height: 170px;
        }

        .zhez {
          position: absolute;
          box-sizing: border-box;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background: rgba(50, 50, 50, 0.8);
          top: 0;
          left: 0;
          width: 288px;
          height: 170px;
          color: #fff;

          h5 {
            text-align: center;
            height: 168px;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            line-height: 170px;
          }

          &:hover {
            border: 1px solid #00f0ff;
            color: #00f0ff;
          }
        }
      }
    }
  }
}

::v-deep .el-card__header {
  border: none;
  height: 40px;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
}

::v-deep .el-input {
  width: calc(100% - 34px);
}

::v-deep .el-input__inner {
  background: none;
  border: none;
  // border-bottom: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 25px;

  &:-webkit-autofill {
    color: #fff !important;
    // -webkit-box-shadow: 0 0 0px 1000px RGBA(11, 36, 92, 01) inset !important;
    -webkit-text-fill-color: #fff;
    -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
  }
}

::v-deep .el-checkbox {
  color: #fff;

  .el-checkbox__inner {
    background-color: rgba(0, 0, 0, 0);
  }

  .el-checkbox__label {
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
  }
}
</style>
