// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'


// 教师课程建设数量分布
export const zjyTeachergetSPOCCourse = params => {
    return request({
        url: '/api/ZjyTeacher/getSPOCCourse',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 教师授课班级数量分布
export const zjyTeachergetSPOCTeaching = params => {
    return request({
        url: '/api/ZjyTeacher/getSPOCTeaching',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 教师课程活动数量分布
export const zjyTeachergetClassActivity = params => {
    return request({
        url: '/api/ZjyTeacher/getClassActivity',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 教学行为统计
export const zjyTeachergetTeachBehavioralStatistics = params => {
    return request({
        url: '/api/ZjyTeacher/getTeachBehavioralStatistics',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出教学行为统计
export const zjyTeacherexportTeachBehavioralStatistics = params => {
    return request({
        url: '/api/ZjyTeacher/exportTeachBehavioralStatistics',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}

// 资源总览
export const zjyTeachergetResource = params => {
    return request({
        url: '/api/ZjyTeacher/getResource',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 教学上传资源情况
export const zjyTeachergetTeacherUploadResource = params => {
    return request({
        url: '/api/ZjyTeacher/getTeacherUploadResource',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出教学上传资源情况
export const zjyTeacherexportTeacherUploadResource = params => {
    return request({
        url: '/api/ZjyTeacher/exportTeacherUploadResource',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}


// 学生行为统计
export const zjyTeachergetStudentBehaviorStatistics = params => {
    return request({
        url: '/api/ZjyTeacher/getStudentBehaviorStatistics',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出学生行为统计
export const zjyTeacherexportStudentBehaviorStatistics = params => {
    return request({
        url: '/api/ZjyTeacher/exportStudentBehaviorStatistics',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部院系列表
export const zjyTeachergetSubList = params => {
    return request({
        url: '/api/ZjyTeacher/getSubList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部专业列表
export const zjyTeachergetMajorList = params => {
    return request({
        url: '/api/ZjyTeacher/getMajorList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部行政班列表
export const zjyTeachergetClassList = params => {
    return request({
        url: '/api/ZjyTeacher/getClassList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部年级列表
export const zjyTeachergetGradeList = params => {
    return request({
        url: '/api/ZjyTeacher/getGradeList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部教学班列表
export const zjyTeachergetOpenClassList = params => {
    return request({
        url: '/api/ZjyTeacher/getOpenClassList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部学期列表
export const zjyTeachergetTermList = params => {
    return request({
        url: '/api/ZjyTeacher/getTermList',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 学生行为统计——全部课程列表
export const zjyTeachergetCourseList = params => {
    return request({
        url: '/api/ZjyTeacher/getCourseList',
        method: 'post',
        data: qs.stringify(params)
    })
}


// 行为分析——职教云教师行-学生行为分析
export const zjyTeachergetTchrBehavioralAnalysis = params => {
    return request({
        url: '/api/ZjyTeacher/getTchrBehavioralAnalysis',
        method: 'post',
        data: qs.stringify(params)
    })
}
