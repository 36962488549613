import request from "@/utils/request";
import qs from "qs";

// 登录
export const goLogin = (params) => {
  return request({
    url: "/api/user/login",
    method: "post",
    data: params
  });
};
// 获取个人信息
export const getUserinfo = (params) => {
  return request.post("/api/common/login/GetUserInfo", qs.stringify(params));
};

// 退出 /icve-dbc/user/logout
export const loginOut = (params) => {
  return request({
    url: "/api/user/logout",
    method: "get",
    params: params
  });
};
export const getSchoolVersion = params => {
  return request({
    url: '/api/school/getSchoolVersion',
    method: 'post',
    params: params
  })
}
// 单点登录
export const tokenLogin = (params) => {
  return request({
    url: "/api/user/tokenLogin",
    method: "get",
    params: params
  });
};
