// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 获取院校统计
// /dataStatistics/getProvinceSchool
export const getProvinceSchool = params => {
  return request({
    url: '/api/dataStatistics/getProvinceSchool',
    method: 'post',
    data: params
  })
}

// 获取院校统计表格
// /dataStatistics/exportProvinceSchool
export const exportProvinceSchool = params => {
  return request({
    url: '/api/dataStatistics/exportProvinceSchool',
    method: 'post',
    responseType: 'blob',
    data: params
  })
}

// 获取课程统计（课程详情列表）
// /dataStatistics/getCourseForSchool
export const getCourseForSchool = params => {
  return request({
    url: '/api/dataStatistics/getCourseForSchool',
    method: 'post',
    data: params
  })
}

// 导出课程统计（学校课程统计列表）
// /dataStatistics/exportCourseForSchool
export const exportCourseForSchool = params => {
  return request({
    url: '/api//dataStatistics/exportCourseForSchool',
    method: 'post',
    responseType: 'blob',
    data: params
  })
}

// 课程统计（每个学校课程详情列表）
// /dataStatistics/getCourseDetailList
export const getCourseDetailList = params => {
  return request({
    url: '/api/dataStatistics/getCourseDetailList',
    method: 'post',
    data: params
  })
}

// 导出课程统计（每个学校课程详情列表）
// /dataStatistics/exportCourseDetailList
export const exportCourseDetailList = params => {
  return request({
    url: '/api/dataStatistics/exportCourseDetailList',
    method: 'post',
    responseType: 'blob',
    data: params
  })
}

// 获取课程统计（课程来源-课程所属资源库）
// /dataStatistics/getCurriculumSource
export const getCurriculumSource = params => {
  return request({
    url: '/api/dataStatistics/getCurriculumSource',
    method: 'post',
    data: qs.stringify(params)
  })
}
