// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 课堂活动教学偏好
// /teachLog/getFaceSignLog
export const getFaceSignLog = params => {
  return request({
    url: '/api/teachLog/getFaceSignLog',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源库日志数据
export const getResourceLogList = params => {
  return request({
    url: '/api/teachLog/getResourceLogList',
    method: 'post',
    data: params
  })
}
// 选择专业群、
export const getProjectName = params => {
  return request({
    url: '/api/teachLog/getProjectName',
    method: 'post',
    params: params
  })
}

// 专业群日志数据
// /teachLog/getZyqLogList
export const getZyqLogList = params => {
  return request({
    url: '/api/teachLog/getZyqLogList',
    method: 'post',
    data: params
  })
}

// 课堂教学概况日志
// /teachLog/getFaceTeachLog
export const getFaceTeachLog = params => {
  return request({
    url: '/api/teachLog/getFaceTeachLog',
    method: 'post',
    data: params
  })
}

// 到课率分布统计
export const getCourseFaceteachinfo = params => {
  return request({
    url: '/api/teachLog/getCourseFaceteachinfo',
    method: 'post',
    params: params
  })
}

// 平均到课率
export const getAvgcourse = params => {
  return request({
    url: '/api/teachLog/getAvgcourse',
    method: 'post',
    params: params
  })
}

// 各个日志访问量折线图
// /teachLog/getUserVisitLog
export const getUserVisitLog = params => {
  return request({
    url: '/api/teachLog/getUserVisitLog',
    method: 'post',
    data: params
  })
}

// 每日教师活跃数
// /teachLog/getActivityTeacherLog
export const getActivityTeacherLog = params => {
  return request({
    url: '/api/teachLog/getActivityTeacherLog',
    method: 'post',
    data: params
  })
}

// 每日学生活跃数
// /teachLog/getActivityStuLog
export const getActivityStuLog = params => {
  return request({
    url: '/api/teachLog/getActivityStuLog',
    method: 'post',
    data: params
  })
}

// 每日课程活跃数
// /teachLog/getActivityCourseLog
export const getActivityCourseLog = params => {
  return request({
    url: '/api/teachLog/getActivityCourseLog',
    method: 'post',
    data: params
  })
}

// 本省尚未参与资源库的学校建设列表
// /teachLog/getNotJoinSchool
export const getNotJoinSchool = params => {
  return request({
    url: '/api/teachLog/getNotJoinSchool',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 一周内无活跃学校列表
// /teachLog/getWeekNotActivitySchool
export const getWeekNotActivitySchool = params => {
  return request({
    url: '/api/teachLog/getWeekNotActivitySchool',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 签到分析
export const getSign = params => {
  return request({
    url: '/api/teachLog/getSign',
    method: 'post',
    data: qs.stringify(params)
  })
}