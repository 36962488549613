<template>
  <div id="app">
    <router-view/>
   
  </div>
</template>
<script>
export default {
    data() {
      return {}
    },
    created() {
      
    },

}
</script>
<style lang="scss" scoped>
#app {
  width: 100vw;
  height: 100vh;
  background-color: #09121D;
  // background-color: green;
  // overflow: hidden;
}
#app > div {
  height: 100vh;
}

</style>