// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 数据总览

// 获取学校列表
export const getSchools = params => {
  return request({
    url: '/api/dataOverviewTotalData/getSchools',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 各个日志量访问折线图
export const GetPvDayData = params => {
  return request({
    url: '/api/dataOverviewTotalData/GetPvDayData',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 头部数据
export const getHeadTotalData = params => {
  return request({
    url: '/api/dataOverviewTotalData/getHeadTotalData',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源建设分析
export const getResourcesAndCourse = params => {
  return request({
    url: '/api/dataOverviewTotalData/getResourcesAndCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 日志来源分布
export const getSource = params => {
  return request({
    url: '/api/dataOverviewTotalData/getSource',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 活跃学生top10
export const getStuTop = params => {
  return request({
    url: '/api/dataOverviewTotalData/getStuTop',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 活跃教师top10
export const getTeaTop = params => {
  return request({
    url: '/api/dataOverviewTotalData/getTeaTop',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 每日活跃教师-学生数
export const getTeaStu = params => {
  return request({
    url: '/api/dataOverviewTotalData/getTeaStu',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 影响力
// 辐射用户省份
export const getProvinceInfo = params => {
  return request({
    url: '/api/dataOverviewTotalData/getProvinceInfo',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 用户校园分布
export const getSchoolDistribution1 = params => {
  return request({
    url: '/api/dataOverviewTotalData/getSchoolDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 用户校园分布列表
export const getSchoolDistributionList = params => {
  return request({
    url: '/api/dataOverviewTotalData/getSchoolDistributionList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 用户来源分布
export const getUser = params => {
  return request({
    url: '/api/dataOverviewTotalData/getUser',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 导出用户校园分布列表
export const exportSchoolDistributionList = params => {
  return request({
    url: '/api/dataOverviewTotalData/exportSchoolDistributionList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}
