// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 院系用户及活跃用户
export const getUserStatistics = params => {
  return request({
    url: '/api/subOverview/getUserStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 院系课程数量
export const getCourseStatistics = params => {
  return request({
    url: '/api/subOverview/getCourseStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程日志量top5
export const getCourseTop = params => {
  return request({
    url: '/api/subOverview/getCourseTop',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 教师日志量top5
export const getTeaTop1 = params => {
  return request({
    url: '/api/subOverview/getTeaTop',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 院系出勤率top10
export const getAttendanceStatistics = params => {
  return request({
    url: '/api/subOverview/getAttendanceStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日活师生数
export const getDayUserStatistics1 = params => {
  return request({
    url: '/api/subOverview/getDayUserStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
