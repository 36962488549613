<template>
    <div class="upload-file">
      <el-upload multiple :action="file_system_url" :data="paramsData" :before-upload="handleBeforeUpload"
        :file-list="uploadFileList || []" :limit="limit" :on-error="handleUploadError" :on-exceed="handleExceed"
        :on-success="handleUploadSuccess" :show-file-list="false" :headers="headers" class="upload-file-uploader"
        ref="upload">
        <!-- 上传按钮 -->
        <el-button size="small" type="plain" class="addBlue" :disabled="btnDisabled">{{
          btnName
        }}</el-button>
        <!-- <span class="limitation" style="color: #999999;font-size: 12px;margin-left: 10px;">只能上传office文件 (<span
            v-for="item, idx in fileType" :key="idx">{{ item }}<span
              v-if="idx != fileType.length - 1">、</span></span>格式)</span> -->
        
        <div class="el-upload__tip" slot="tip" v-if="showTip">
          <template v-if="limit">
            文件总数不超过 <b style="color: #c83636">{{ limit }}</b> 个</template>
        </div>
      </el-upload>
  
      <!-- 文件列表 -->
      <transition-group class="upload-file-list el-upload-list el-upload-list--text" name="el-fade-in-linear" tag="ul"
        v-if="isShowList">
        <li :key="index" class="el-upload-list__item ele-upload-list__item-content" v-for="(file, index) in uploadFileList">
          <el-link @click="download(file.ossOriUrl)" :underline="false" target="_blank">
            <span class="el-icon-document"> {{ file.fileName }} </span>
          </el-link>
          <div class="ele-upload-list__item-content-action">
            <el-link :underline="false" @click="handleDelete(index)" type="danger">删除</el-link>
          </div>
        </li>
      </transition-group>
    </div>
  </template>
  
  <script>
  import { getToken } from "@/utils/auth";
  import axios from "axios";
  export default {
    name: "FileUpload",
    props: {
      btnDisabled: {
        type: Boolean,
        default: false
      },
      // 值
      // value: [String, Object, Array],
      uploadFileList: {
        type: Array,
      },
      // 数量限制
      limit: {
        type: Number,
        default: 6,
      },
      // 大小限制(MB)
      fileSize: {
        type: Number,
        default: 1000,
      },
      fileType: {
        type: Array,
        default: () => [
          "doc",
          "docx",
          "xls",
          "xlsx",
          "ppt",
          "pptx",
          "txt",
          "pdf",
          "wps",
          "wpt",
          "dps",
          "rtf",
          "jpg",
          "jpeg",
          "png",
          "tif",
          "tiff",
          "bmp",
          "gif",
          "flv",
          "swf",
          "asf",
          "avi",
          "mpg",
          "3gp",
          "mp3",
          "mp4",
          "wav",
          "rm",
          "rmvb",
          "wmv",
        ],
      },
      // 是否显示提示
      isShowTip: {
        type: Boolean,
        default: true,
      },
      parentId: {
        type: Number,
        default: 0,
      },
      btnName: {
        type: String,
        default: "上传附件 ",
      },
      isShowList: {
        type: Boolean,
        default: true,
      },
  
      //以下三个必传
      file_system_url: {
        type: String,
        default: '',
      },
      signature: {
        type: Object,
      },
      paramsData: {
        type: Object,
      },
    },
    data() {
      return {
        number: 0,
        uploadList: [],
        baseUrl: process.env.VUE_APP_BASE_API,
        uploadFileUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传的图片服务器地址
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      };
    },
  
    computed: {
      // 是否显示提示
      showTip() {
        return this.isShowTip && (this.fileType || this.fileSize);
      },
    },
  
    methods: {
      download(url) {
        window.open(url, "_system");
      },
      // 上传前校检格式和大小
      handleBeforeUpload(file) {
        // console.log("file", file);
        this.paramsData.key = this.paramsData.key + file.name;
        // 校检文件类型
        if (this.fileType) {
          let fileExtension = "";
          if (file.name.lastIndexOf(".") > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
          }
          const isTypeOk = this.fileType.some((type) => {
            // if (file.type.indexOf(type) > -1) return true;
            if (fileExtension && fileExtension.indexOf(type) > -1) return true;
            return false;
          });
          if (!isTypeOk) {
            this.$modal.msgError(`文件格式不正确, 请上传指定格式文件!`);
            return false;
          }
        }
        // 校检文件大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return false;
          }
        }
        this.$modal.loading("正在上传文件，请稍候...");
        this.number++;
        return true;
      },
      // 文件个数超出
      handleExceed() {
        this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
      },
      // 上传失败
      handleUploadError(err) {
        this.$modal.msgError("上传失败，请重试");
        this.$modal.closeLoading();
      },
      // 上传成功回调
      handleUploadSuccess(res, file) {
        // res.id = "";
        // res.status = "0";
        // res.fileName = file.name;
        // res.parentId = this.parentId;
        // console.log("上传成功", [res]);
        // this.$emit("input", res);
        // this.$modal.closeLoading();
        // // console.log('this.uploadFileList',this.uploadFileList)
        if (res.code == 1) {
          res.fileName = file.name;
          this.$modal.closeLoading();
          console.log(this.uploadFileList, res);
          this.$emit("successData", [...this.uploadFileList, res]);
        }
      },
      // 删除文件
  
      handleDelete(index) {
        this.uploadFileList.splice(index, 1);
        this.$emit("successData", this.uploadFileList);
      },
      // 获取文件名称
      getFileName(name) {
        if (name.lastIndexOf("/") > -1) {
          return name.slice(name.lastIndexOf("/") + 1);
        } else {
          return "";
        }
      },
      // 对象转成指定字符串分隔
      listToString(list, separator) {
        let strs = "";
        separator = separator || ",";
        for (let i in list) {
          strs += list[i].url + separator;
        }
        return strs != "" ? strs.substr(0, strs.length - 1) : "";
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  // .upload-file-uploader {
  //   // margin-bottom: 5px;
  // }
  
  .upload-file-list .el-upload-list__item {
    border: 1px solid #e6e6e6;
    line-height: 2;
    margin-bottom: 10px;
    position: relative;
  }
  
  .upload-file-list .ele-upload-list__item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: inherit;
  }
  
  .ele-upload-list__item-content-action .el-link {
    margin-right: 10px;
  }
  </style>