<template>
  <div class="common-aside">
    <el-menu router :default-active="$route.path" class="el-menu-vertical-demo" text-color="#52596F"
      active-text-color="#fff">
      <div v-for="menu in $store.state.provinceMenuList" :key="menu.index">
        <template v-if="menu.show == true">
          <el-menu-item :index="menu.path" v-if="!(menu.childList && menu.childList.length > 0)">
            <img class="isHidd" :src="menu.icon" />
            <img class="isShow" :src="menu.iconA" />
            <span slot="title">{{ menu.rightName }}</span>
          </el-menu-item>
          <el-submenu :index="menu.rightName" v-if="menu.childList && menu.childList.length > 0">
            <template slot="title">
              <img class="isHidd" :src="menu.icon" />
              <img class="isShow" :src="menu.iconA" />
              <span>{{ menu.rightName }}</span>
            </template>
            <el-menu-item-group>
              <template  v-for="subMenu in menu.childList" >
                <el-menu-item v-if="menu.show == true" :key="subMenu.index" :index="subMenu.path">
                <img class="isShow" :src="subMenu.iconA" />
                <img class="isHidd" :src="subMenu.icon" />
                {{ subMenu.rightName }}
              </el-menu-item>
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>

      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu2Show: true
    }
  }
}
</script>
