<!--
 * @Author: yejian
-->
<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import { autoToolTip2 } from "@/utils/echartsAutoTooltip2.js";
import * as echarts from 'echarts';
export default {
    name: 'echart',
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            chart2: null
        }
    },
    watch: {
        options: {
            handler(options) {
                // 设置true清空echart缓存
                this.chart2.setOption(options, true)
            },
            deep: true
        }
    },
    mounted() {
        this.initChart();
    },
    beforeDestroy() {
        this.chart2.dispose()
        this.chart2 = null
    },
    methods: {
        initChart() {
            // 初始化echart
            this.chart2 = echarts.init(this.$el)
            this.chart2.setOption(this.options, true)
            setTimeout(() => {
                autoToolTip2(this.chart2, this.options, {
                    interval: 2000,// 轮播间隔时间 默认2s
                    loopSeries: false,// 是否循环轮播所有序列
                    seriesIndex: 30,// 第1个被轮播的序列下标
                })
            }, 1000);

        }
    }
}
</script>

<style></style>