// 运营数据中心

// 引入axios 实例
import request from "@/utils/request";
import qs from "qs";

// 数据概览
//智慧职教-职教云-mooc 等数据概览
export const getDataOverview = (params) => {
  return request({
    url: "/api/opeDataOverview/getDataOverview",
    method: "post",
    data: params,
  });
};

// 智慧职教活跃项目排行
export const getActiveProjectTop = (params) => {
  return request({
    url: "/api/opeDataOverview/getActiveProjectTop",
    method: "post",
    data: params,
  });
};

//智慧职教资源贡献排行
export const getResourceContributionTop = (params) => {
  return request({
    url: "/api/opeDataOverview/getResourceContributionTop",
    method: "post",
    data: params,
  });
};

//职教云活跃院校排行
export const getZjyActivityschoolTop = (params) => {
  return request({
    url: "/api/opeDataOverview/getZjyActivityschoolTop",
    method: "post",
    data: params,
  });
};

//MOOC院校活跃课程排行
export const getMoocActivitycourseTop = (params) => {
  return request({
    url: "/api/opeDataOverview/getMoocActivitycourseTop",
    method: "post",
    data: params,
  });
};

// 用户统计

// 用户总数-按身份统计
export const getUserStatisticsOperation = (params) => {
  return request({
    url: "/api/opeDataUser/getUserStatistics",
    method: "post",
    data: params,
  });
};
// 用户总数-按省份统计
export const getProvinceUserDistribution = (params) => {
  return request({
    url: "/api/opeDataUser/getProvinceUserDistribution",
    method: "post",
    data: params,
  });
};

// 用户总数-按院校统计
export const getSchoolUserDistribution = (params) => {
  return request({
    url: "/api/opeDataUser/getSchoolUserDistribution",
    method: "post",
    data: params,
  });
};

// 用户总数-用户增长曲线
export const getUserIncrease = (params) => {
  return request({
    url: "/api/opeDataUser/getUserIncrease",
    method: "post",
    data: params,
  });
};

// 用户总数-活跃老师排行榜
export const getActiveTeaLogTop10 = (params) => {
  return request({
    url: "/api/opeDataUser/getActiveTeaLogTop10",
    method: "post",
    data: params,
  });
};

// 用户总数-活跃学生排行榜
export const getActiveStuLogTop10 = (params) => {
  return request({
    url: "/api/opeDataUser/getActiveStuLogTop10",
    method: "post",
    data: params,
  });
};

// 素材统计-智慧职教素材情况-职教云素材情况-mooc学院素材情况
export const getOperateOverview = (params) => {
  return request({
    url: "/api/opeDataMaterial/getOperateOverview",
    method: "post",
    data: params,
  }); 
};


// 素材统计-素材的媒体类型分布
export const getIcveMediatype = (params) => {
  return request({
    url: "/api/opeDataMaterial/getIcveMediatype",
    method: "post",
    data: params,
  }); 
};

// 素材统计-素材的应用类型分布
export const getApplicationtype = (params) => {
  return request({
    url: "/api/opeDataMaterial/getApplicationtype",
    method: "post",
    data: params,
  }); 
};

// 素材统计-素材的来源分布-素材的适用对象分布
export const getOperateSourceObject = (params) => {
  return request({
    url: "/api/opeDataMaterial/getOperateSourceObject",
    method: "post",
    data: params,
  }); 
};

// 素材统计-素材的活跃资源占比-被组课引用的资源占比
export const getActiveResource = (params) => {
  return request({
    url: "/api/opeDataMaterial/getActiveResource",
    method: "post",
    data: params,
  }); 
};

// 素材统计-素材增长
export const getMediaIncrease = (params) => {
  return request({
    url: "/api/opeDataMaterial/getMediaIncrease",
    method: "post",
    data: params,
  }); 
};


// 试题增长 
// 试题总量-主观题与客观题比例图-试题题型比例-试题难度分布比例图-被组课引用的试题数
export const getQuestionStatistics = (params) => {
  return request({
    url: "/api/opeDataQuestion/getQuestionStatistics",
    method: "post",
    data: params,
  }); 
};

// 试题总量-试题增长图
export const getQuestionIncrease = (params) => {
  return request({
    url: "/api/opeDataQuestion/getQuestionIncrease",
    method: "post",
    data: params,
  }); 
};

//
// 课程统计-课程来源分布
export const getCourseSourceList = (params) => {
  return request({
    url: "/api/opeDataCourse/getCourseSource",
    method: "post",
    data: params,
  }); 
};

// 课程统计-课程数量增长图
export const getCoursenumIncrease = (params) => {
  return request({
    url: "/api/opeDataCourse/getCoursenumIncrease",
    method: "post",
    data: params,
  }); 
};

// 课程统计-课程详情列表
export const getCourseDetail = (params) => {
  return request({
    url: "/api/opeDataCourse/getCourseDetail",
    method: "post",
    data: params,
  }); 
};

//
//资源新增和调用
export const getResourceNewInvoke = (params) => {
  return request({
    url: "/api/opeDataResources/getResourceNewInvoke",
    method: "post",
    data: params,
  }); 
};
//课程的新增和调用
export const getCourseInvoke = (params) => {
  return request({
    url: "/api/opeDataResources/getCourseInvoke",
    method: "post",
    data: params,
  }); 
};

//ICVE课程被调用情况
export const getIcveCourseCited = (params) => {
  return request({
    url: "/api/opeDataResources/getIcveCourseCited",
    method: "post",
    data: params,
  }); 
};

//试题的新增和调用
export const getQuestionNewInvoke = (params) => {
  return request({
    url: "/api/opeDataResources/getQuestionNewInvoke",
    method: "post",
    data: params,
  }); 
};

//
//资源库新增项目详情
export const getIcveAddProject = (params) => {
  return request({
    url: "/api/opeDataAddDetail/getIcveAddProject",
    method: "post",
    data: params,
  }); 
};

//职教云新增详情
export const getZjyAddDetail = (params) => {
  return request({
    url: "/api/opeDataAddDetail/getZjyAddDetail",
    method: "post",
    data: params,
  }); 
};

//mooc学院新增详情（按课程数）
export const getMoocAddCourseDetai = (params) => {
  return request({
    url: "/api/opeDataAddDetail/getMoocAddCourseDetai",
    method: "post",
    data: params,
  }); 
};

//mooc学院新增详情（按期数）
export const getMoocAddDetail = (params) => {
  return request({
    url: "/api/opeDataAddDetail/getMoocAddDetail",
    method: "post",
    data: params,
  }); 
};

// 日志
// 日志-按来源统计日志
export const getTotalLog = (params) => {
  return request({
    url: "/api/opeDataLog/getTotalLog",
    method: "post",
    data: params,
  }); 
};

// 日志-按省份统计日志
export const getLogNumProvince = (params) => {
  return request({
    url: "/api/opeDataLog/getLogNumProvince",
    method: "post",
    data: params,
  }); 
};

// 日志-日志量曲线图
export const getLogNumCurve = (params) => {
  return request({
    url: "/api/opeDataLog/getLogNumCurve",
    method: "post",
    data: params,
  }); 
};

