// 引入axios 实例
import request from "@/utils/request";
// import qs from 'qs'

// 获取数据概览
// /dataStatistics/getProvinceSchool
export const getDataInfo = (params) => {
  return request({
    url: "/api/schoolProject/getDataInfo",
    method: "post",
    data: params,
  });
};

// 获取群数据
export const getZyqDataSituation = (params) => {
  return request({
    url: "/api/schoolProject/getZyqDataSituation",
    method: "post",
    data: params,
  });
};

// 获取素材总量分布
export const getMaterialTotal = (params) => {
    return request({
      url: "/api/schoolProject/getMaterialTotal",
      method: "post",
      params: params,
    });
  };

// 获取开通学校情况
export const getOpenSchoolSituation = (params) => {
    return request({
      url: "/api/schoolProject/getOpenSchoolSituation",
      method: "post",
      data: params,
    });
  };
 
// 获取本省高职院校参与资源库项目情况
export const getProjectJoinSituation = (params) => {
  return request({
    url: "/api/schoolProject/getProjectJoinSituation",
    method: "post",
    data: params,
  });
};

// 获取专业群列表
export const getZyqList = (params) => {
  return request({
    url: "/api/schoolProject/getZyqList",
    method: "post",
    data: params,
  });
};

// 获取本省高职院校参与专业群情况
export const getZyqJoinSituation = (params) => {
  return request({
    url: "/api/schoolProject/getZyqJoinSituation",
    method: "post",
    data: params,
  });
};

// 获取按照期次|按课程数-申请与上线课程期数、省份排名
export const getCourseApplyMinc = (params) => {
  return request({
    url: "/api/schoolProject/getCourseApplyMinc",
    method: "post",
    data: params,
  });
};

// 获取按照期次|按课程数-各期次开课与结课数量
export const getCyclenumberDf = (params) => {
  return request({
    url: "/api/schoolProject/getCyclenumberDf",
    method: "post",
    data: params,
  });
};
