// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 获取资源建设排行
// /rankingList/getResourcesByProvince
export const getResourcesByProvince = params => {
  return request({
    url: '/api/rankingList/getResourcesByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取课程建设排行
// /rankingList/getCourseByProvince
export const getCourseByProvince = params => {
  return request({
    url: '/api/rankingList/getCourseByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取活跃院校排行
// /rankingList/getActiveSchoolByProvince
export const getActiveSchoolByProvince = params => {
  return request({
    url: '/api/rankingList/getActiveSchoolByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取影响力院校排行
// /rankingList/getUsingSchoolByProvince
export const getUsingSchoolByProvince = params => {
  return request({
    url: '/api/rankingList/getUsingSchoolByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取课程应用排行
// /rankingList/getActiveCourseByProvince
export const getActiveCourseByProvince = params => {
  return request({
    url: '/api/rankingList/getActiveCourseByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取影响力课程排行
// /rankingList/getIcveUsingCourseByProvince
export const getIcveUsingCourseByProvince = params => {
  return request({
    url: '/api/rankingList/getIcveUsingCourseByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取活跃老师排行
// /rankingList/getActiveTeacherByProvince
export const getActiveTeacherByProvince = params => {
  return request({
    url: '/api/rankingList/getActiveTeacherByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取活跃学生排行
// /rankingList/getActiveStudentByProvince
export const getActiveStudentByProvince = params => {
  return request({
    url: '/api/rankingList/getActiveStudentByProvince',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源贡献排行榜
// /rankingList/getContributionSchool
export const getContributionSchool = params => {
  return request({
    url: '/api/rankingList/getContributionSchool',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 最热mooc排行榜
// /rankingList/getMoocCourseRangKing
export const getMoocCourseRangKing = params => {
  return request({
    url: '/api/rankingList/getMoocCourseRangKing',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源被学习情况
// /rankingList/getRecourseStudyList
export const getRecourseStudyList = params => {
  return request({
    url: '/api/rankingList/getRecourseStudyList',
    method: 'post',
    data: qs.stringify(params)
  })
}
