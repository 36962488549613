<!--
 * @Author: yejian
-->
<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts';
export default {
    name: 'echart',
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        options: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            chart1: null
        }
    },
    watch: {
        options: {
            handler(options) {
                // 设置true清空echart缓存
                this.chart1.setOption(options, true)
            },
            deep: true
        }
    },
    mounted() {
        this.initChart();
    },
    beforeDestroy() {
        this.chart1.dispose()
        this.chart1 = null
    },
    methods: {
        initChart() {
            // 初始化echart
            this.chart1 = echarts.init(this.$el)
            this.chart1.setOption(this.options, true)
            console.log('轮播图2');
            // console.log(this.chart);
            setInterval(() => {
                console.log(this.chart1.getOption(), 'asfsdafas');
                this.chart1.dispatchAction({
                    type: 'dataZoom',
                    batch: true,
                    startValue: Math.max(0, this.chart1.getOption().dataZoom[0].startValue + 1),
                    endValue: Math.min(100, this.chart1.getOption().dataZoom[0].endValue + 1),
                });
            }, 2000); // 每2秒轮播一次
        }
    }
}
</script>

<style></style>