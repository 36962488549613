// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 职教云院校列表
export const getZjySchoolList = (params) => {
    return request({
        url: '/api/dataStatistics/getZjySchoolList',
        method: 'post',
        data: params
    })
}

// 导出职教云院校列表
export const exportZjySchoolList = params => {
    return request({
        url: '/api/dataStatistics/exportZjySchoolList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}

// 资源库院校列表(汇总统计)
export const getZykSchoolCountList = (params) => {
    return request({
        url: '/api/dataStatistics/getZykSchoolCountList',
        method: 'post',
        data: params
    })
}

// 导出资源库院校列表(汇总统计)
export const exportZykSchoolCountList = params => {
    return request({
        url: '/api/dataStatistics/exportZykSchoolCountList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}

// 资源库院校列表（版本开通情况）
export const getZykSchoolList = (params) => {
    return request({
        url: '/api/dataStatistics/getZykSchoolList',
        method: 'post',
        data: params
    })
}

// 导出资源库院校列表(版本开通情况)
export const exportZykSchoolList = params => {
    return request({
        url: '/api/dataStatistics/exportZykSchoolList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}

// 教研室院校列表(汇总统计)
export const getJysSchoolCountList = (params) => {
    return request({
        url: '/api/dataStatistics/getJysSchoolCountList',
        method: 'post',
        data: params
    })
}

// 导出教研室院校列表(汇总统计)
export const exportJysSchoolCountList = params => {
    return request({
        url: '/api/dataStatistics/exportJysSchoolCountList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}

// 教研室院校列表（版本开通情况）
export const getJysSchoolList = (params) => {
    return request({
        url: '/api/dataStatistics/getJysSchoolList',
        method: 'post',
        data: params
    })
}

// 导出教研室院校列表(版本开通情况)
export const exportJysSchoolList = params => {
    return request({
        url: '/api/dataStatistics/exportJysSchoolList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}

// mooc院校列表（版本开通情况）
export const getMoocSchoolList = (params) => {
    return request({
        url: '/api/dataStatistics/getMoocSchoolList',
        method: 'post',
        data: params
    })
}

// 导出教研室院校列表(版本开通情况)
export const exportMoocSchoolList = params => {
    return request({
        url: '/api/dataStatistics/exportMoocSchoolList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}

// 大数据院校列表（版本开通情况）
export const getBigDataSchoolList = (params) => {
    return request({
        url: '/api/dataStatistics/getBigDataSchoolList',
        method: 'post',
        data: params
    })
}

// 导出大数据院校列表(版本开通情况)
export const exportBigDataSchoolList = params => {
    return request({
        url: '/api/dataStatistics/exportBigDataSchoolList',
        method: 'post',
        responseType: 'blob',
        data: params
    })
}