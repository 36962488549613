<template>
  <div>
    <el-container>
      <el-header>
        <common-head />
      </el-header>
      <el-container>
        <el-aside width="200px">
          <common-aside />
        </el-aside>
        <el-main>
          <!-- <nav-tabs/> -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonAside from "./components/CommonAside.vue";
import CommonHead from "./components/CommonHead";
// import NavTabs from './components/NavTabs.vue'
export default {
  name: "LayOut",
  components: {
    CommonAside,
    CommonHead,
    // NavTabs
  },
  created() {
    console.log(11111111, "2222");
  },
};
</script>
