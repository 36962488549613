// 引入axios 实例
import request from "@/utils/request";
import qs from "qs";

// 角色列表
export const rolePageList = (params) => {
  return request({
    url: "/api/role/pageList",
    method: "get",
    params: params,
  });
};

//   /role/save 角色新增
export const rolePostAdd = (params) => {
  return request({
    url: "/api/role/save",
    method: "post",
    data: params,
  });
};

// /role/delete 删除
export const rolePostDelete = (params) => {
  return request({
    url: "/api/role/delete",
    method: "GET",
    params: params,
  });
};

// /role/roleRightTree 角色管理-权限树
export const roleRightTree = (params) => {
  return request({
    url: "/api/role/roleRightTree",
    method: "GET",
    params: params,
  });
};
//   /role/updateRoleRight 更新角色权限
export const updateRoleRight = (params) => {
  return request({
    url: "/api/role/updateRoleRight",
    method: "POST",
    data: params,
  });
};

//   /role/roleUserList  获取角色下的成员列表
export const roleUserList = (params) => {
  return request({
    url: "/api/role/roleUserList",
    method: "POST",
    data: params,
  });
};

// /school/querySchoolList 获取学校列表
export const querySchoolList = (params) => {
  return request({
    url: "/api/school/querySchoolList",
    method: "POST",
    data: params,
  });
};

// /user/querySchoolUserList  搜索学校下的用户信息
export const querySchoolUserList = (params) => {
  return request({
    url: "/api/user/querySchoolUserList",
    method: "POST",
    data: params,
  });
};

// /role/addUser 添加角色下的成员
export const addUser = (params) => {
  return request({
    url: "/api/role/addUser",
    method: "POST",
    data: params,
  });
};

//  删除角色下的成员
export const deleteUser = (params) => {
  return request({
    url: "/api/role/deleteUser",
    method: "get",
    params: params,
  });
};

// 获取用户权限列表
export const queryUserRight = (params) => {
  return request({
    url: "/api/user/queryUserRight",
    method: "get",
    params: params,
  });
};

// /user/saveUserRight 保存用户权限

export const saveUserRight = (params) => {
  return request({
    url: "/api/user/saveUserRight",
    method: "post",
    data: params,
  });
};

// 获取省份 icve-dbc/province/list
export const provinceList = (params) => {
  return request({
    url: "/api/province/list",
    method: "get",
    params: params,
  });
};

///user/editManageArea  编辑用户管理区域
export const editManageArea = (params) => {
  return request({
    url: "/api/user/editManageArea",
    method: "post",
    data: params,
  });
};

// /user/queryUserManageArea 获取用户已选的管理区域
export const queryUserManageArea = (params) => {
  return request({
    url: "/api/user/queryUserManageArea",
    method: "GET",
    params: params,
  });
};

// /user/queryUserList 查询用户管理列表
export const queryUserList = (params) => {
  return request({
    url: "/api/user/queryUserList",
    method: "POST",
    data: params,
  });
};

// /school/schoolTypeList 获取学校类型
export const schoolTypeList = (params) => {
  return request({
    url: "/api/school/schoolTypeList",
    method: "get",
    params: params,
  });
};

// /school/schoolManageList 校级数据中心管理列表
export const schoolManageList = (params) => {
  return request({
    url: "/api/school/schoolManageList",
    method: "POST",
    data: params,
  });
};

// /school/batchOperateSchool 批量学校操作(启用-禁用)
export const batchOperateSchool = (params) => {
  return request({
    url: "/api/school/batchOperateSchool",
    method: "POST",
    data: params,
  });
};
export const sysLoggetPageList = (params) => {
  return request({
    url: "/api/sysLog/getPageList",
    method: "POST",
    data: params,
  });
};
export const schoolgetUnCheckSchoolList = (params) => {
  return request({
    url: "/api/school/getUnCheckSchoolList",
    method: "POST",
    data: qs.stringify(params),
  });
};
export const schoolimportSchool = (params) => {
  return request({
    url: "/api/school/importSchool",
    method: "POST",
    data: params,
  });
};
export const schoolsetSchoolVersion = (params) => {
  return request({
    url: "/api/school/setSchoolVersion",
    method: "POST",
    data: params,
  });
};
export const schoolsendSms = (params) => {
  return request({
    url: "/api/school/sendSms",
    method: "POST",
    data: qs.stringify(params),
  });
};
export const schooleditSchool = (params) => {
  return request({
    url: "/api/school/editSchool",
    method: "POST",
    data: params,
  });
};
export const schoolsetSchoolLogo = (params) => {
  return request({
    url: "/api/school/setSchoolLogo",
    method: "POST",
    data: qs.stringify(params),
  });
};
export const schoolsetOpen = (params) => {
  return request({
    url: "/api/school/setOpen",
    method: "POST",
    data: qs.stringify(params),
  });
};
export const schooleditUserSchoolManageArea = (params) => {
  return request({
    url: "/api/school/editUserSchoolManageArea",
    method: "POST",
    data: params,
  });
};
export const schoolsetAuthority = (params) => {
  return request({
    url: "/api/school/setAuthority",
    method: "POST",
    data: qs.stringify(params),
  });
};
export const sysRoleAuthoritygetDetailByRoleId = (params) => {
  return request({
    url: "/api/sysRoleAuthority/getDetailByRoleId",
    method: "POST",
    data: qs.stringify(params),
  });
};
export const sysRoleAuthoritysaveRoleAuthority = (params) => {
  return request({
    url: "/api/sysRoleAuthority/saveRoleAuthority",
    method: "POST",
    data: params,
  });
};
export const sysRoleAuthoritySetDemoUser = (params) => {
  return request({
    url: "/api/role/setDemoUser",
    method: "POST",
    data: qs.stringify(params),
  });
};
