import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import dataV from "@jiaminghi/data-view"
import Echart from './components/echart/index.vue'
import EchartAuto from './components/echart/autoLoopChart.vue'
import EchartAuto2 from './components/echart/autoLoopChart2.vue'
import EchartAuto3 from './components/echart/autoLoopChart3.vue'
import MoreUpload from "@/components/upload/moreUpload"
import scroll from "vue-seamless-scroll"
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import "@/style/font.css"
import "echarts-wordcloud/dist/echarts-wordcloud.js";
import "echarts-wordcloud/dist/echarts-wordcloud.min.js";
// import 'default-passive-events'
import plugins from "./plugins"; // plugins

// 引入接口
import api from '@/API/index.js'
import * as pc from '@/utils/public.js'
import * as ram from '@/utils/token.js'
import '@/style/index.scss'
import './assets/font/font.css'
import './permission.js'
import ImageUpload from '@/components/ImageUpload'
// AI所需组件
// import microApp from "@micro-zoe/micro-app";
// microApp.start()
// import 'amfe-flexible/index.js'
// import '@/assets/js/setRem'
// import JsonExcel from 'vue-json-excel'
// import "@/store/show.js"
Vue.use(ElementUI)
Vue.use(plugins);
Vue.use(dataV)
Vue.use(scroll)

Vue.component('ImageUpload',ImageUpload)
Vue.component("Echart",Echart)
Vue.component('MoreUpload', MoreUpload)
Vue.component("EchartAuto",EchartAuto)
Vue.component("EchartAuto2",EchartAuto2)
Vue.component("EchartAuto3",EchartAuto3)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
// Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$ram = ram
Vue.prototype.$api = api
Vue.prototype.$pc = pc

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
