<template>
  <div>
    <el-container>
      <el-header>
        <common-head @gobreak="gobreak"/>
      </el-header>
      <el-container>
        <el-aside>
          <common-aside v-if="isRouterAlive"/>
        </el-aside>
        <el-main id="scrollTop">
        <nav-tabs v-if="isRouterAlive" @gobreak="gobreak2"/>
        <router-view v-if="isRouterAlive2"/>
      </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import CommonAside from './components/CommonAside.vue'
import CommonHead from './components/CommonHead'
import NavTabs from './components/NavTabs.vue'
export default {
  name: 'LayOut',
  components: {
    CommonAside,
    CommonHead,
    NavTabs
  },
  data () {
    return {
      isRouterAlive: true,
      isRouterAlive2: false
    }
  },
  methods: {
    gobreak () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    gobreak2 () {
      this.isRouterAlive2 = false
      this.$nextTick(() => {
        this.isRouterAlive2 = true
      })
    }
  }
}
</script>
