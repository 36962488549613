// 引入axios 实例
import request from "@/utils/request";
import qs from "qs";
// 菜单列表
export const listMenu = () => {
  return request({
    url: "/api/role/getRightAllList",
    method: "get",
  });
};
export const saveRight = (data) => {
  return request({
    url: "/api/role/saveRight",
    method: "post",
    data
  });
};
export const deleteRight = (data) => {
  return request({
    url: "/api/role/deleteRight",
    method: "post",
    data: qs.stringify(data)
  });
};
