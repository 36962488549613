// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// MOOC

// 头部基本数据
export const getMoocTotalData = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getMoocTotalData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程期数来源
export const getMoocCourseStatistics = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getMoocCourseStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 试题来源分布
export const getQuestionDistribution = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getQuestionDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 开课期数对应课程数
export const getMoocData = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getMoocData',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课程统计
// 课程建设
export const getMoocCourseData = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getMoocCourseData',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学习人数
export const getStudyNum = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getStudyNum',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 获取证书统计图
export const getCertificate = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getCertificate',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程资源分析
export const getMoocCourseResource = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getMoocCourseResource',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程详情
export const getMoocCourseList1 = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/getMoocCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程详情导出
export const exportMoocCourseList = params => {
  return request({
    url: '/api/moocOverviewTotalDataController/exportMoocCourseList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 课程详情
// 头部
export const getCourseNameAuthorRepository1 = params => {
  return request({
    url: '/api/moocCourse/getCourseNameAuthorRepository',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程资源分布
export const getCourseCellTypeDistribution1 = params => {
  return request({
    url: '/api/moocCourse/getCourseCellTypeDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 资源来源
export const getCourseCellSourceDistribution = params => {
  return request({
    url: '/api/moocCourse/getCourseCellSourceDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 试题分布
export const getQuestionDistribution1 = params => {
  return request({
    url: '/api/moocCourse/getQuestionDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志总量分布人数统计
export const getLogCountStatistics = params => {
  return request({
    url: '/api/moocCourse/getLogCountStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 各校用户及活跃度
export const getSchoolDistribution3 = params => {
  return request({
    url: '/api/moocCourse/getSchoolDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户学校分布
export const getUserStatistics1 = params => {
  return request({
    url: '/api/moocCourse/getUserStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户来源分布
export const getMOOCProvinceDistribution = params => {
  return request({
    url: '/api/moocCourse/getMOOCProvinceDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课程日志量
export const getLogDayStatistics2 = params => {
  return request({
    url: '/api/moocCourse/getLogDayStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日活学生数
export const getDayStuStatistics1 = params => {
  return request({
    url: '/api/moocCourse/getDayStuStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 月活学生数
export const getMonthStuStatistics1 = params => {
  return request({
    url: '/api/moocCourse/getMonthStuStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 发帖统计
export const getMOOCPostingStatistics = params => {
  return request({
    url: '/api/moocCourse/getMOOCPostingStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 成绩分布
export const getGradeCount = params => {
  return request({
    url: '/api/moocCourse/getGradeCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 获得证书统计
export const getCertCount = params => {
  return request({
    url: '/api/moocCourse/getCertCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学生学习详情列表
export const getCourseStudentStudyList = params => {
  return request({
    url: '/api/moocCourse/getCourseStudentStudyList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 排行榜
// 活跃学生top10
export const getActivityStu = params => {
  return request({
    url: '/api/ranking/getActivityStu',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 选课人数排行top10
export const getChooseCourse = params => {
  return request({
    url: '/api/ranking/getChooseCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// MOOC活跃课程top10
export const getMoocCourse1 = params => {
  return request({
    url: '/api/ranking/getMoocCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
