<template>
  <div class="common-aside">
    <el-menu
      router
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      text-color="#52596F"
      active-text-color="#fff"
    >
      <div v-for="menu in MenuList" :key="menu.index">
        <template v-if="menu.show == true">
          <el-menu-item
            :index="menu.path"
            v-if="!(menu.childList && menu.childList.length > 0)"
          >
            <img class="isHidd" :src="menu.icon" />
            <img class="isShow" :src="menu.iconA" />
            <span slot="title">{{ menu.rightName }}</span>
          </el-menu-item>
          <el-submenu
            :index="menu.rightName"
            v-if="menu.childList && menu.childList.length > 0"
          >
            <template slot="title">
              <img class="isHidd" :src="menu.icon" />
              <img class="isShow" :src="menu.iconA" />
              <span>{{ menu.rightName }}</span>
            </template>
            <el-menu-item-group>
              <template v-for="subMenu in menu.childList">
                <el-menu-item
                  v-if="menu.show == true"
                  :key="subMenu.index"
                  :index="subMenu.path"
                >
                  <img class="isShow" :src="subMenu.iconA" />
                  <img class="isHidd" :src="subMenu.icon" />
                  {{ subMenu.rightName }}
                </el-menu-item>
              </template>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu2Show: true,
      MenuList: [],
    };
  },
  methods: {
    // 获取所属专业群列表
    async getProjectGroupBySchoolId() {
      try {
        const { data: res } = await this.$api.getProjectGroupBySchoolId({
          schoolId: sessionStorage.getItem("schoolId"),
        });

        if (res.code === 2000) {
          // console.log(res)

          if (res.data.length > 0) {
            this.MenuList = this.$store.state.schoolMenuList;
          } else {
            this.MenuList = this.$store.state.schoolMenuList.filter((item) => {
              return item.rightId != 115;
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getProjectGroupBySchoolId();
    // console.log(
    //   this.$store.state.schoolMenuList,
    //   "$store.state.schoolMenuList"
    // );
  },
};
</script>
