<template>
  <div class="nav-tabs">
    <div class="tab-containers">
      <div v-if="menu.some(item => $route.path === item.path)">
        <div v-for="tab in menu" :key="tab.path">
          <div v-if="$route.path === tab.path" class="tab">
            <span>{{ tab.rightName }}</span>
            <span class="zyqselect" v-if="tab.isZyq">所属专业群：
              <el-select size="small" v-model="selectValue" placeholder="专业群" @change="selectChange">
                <el-option
                  v-for="item in selectoptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </span>
          </div>
        </div>
      </div>
      <div v-else  class="tab active1">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <!-- <el-breadcrumb-item :to="{ path: '/school' }">首页</el-breadcrumb-item> -->
          <el-breadcrumb-item v-for="item in tabList" :key="item.id"
          :to="{ path: item.path }">{{ item.label }}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="text" @click="$router.go(-1)"><img src="@/assets/school/icon_return.png" alt="">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      title: '',
      menu: [],
      tabList: [],
      menuList: [],
      selectoptions: [],
      selectValue: ''
    }
  },
  watch: {
    $route (to, from) {
      // console.log('路由变化了')
      // console.log('当前页面路由：' + to)
      // console.log('上一个路由：' + from)
      this.setMenu()
    }
  },
  created () {
    // console.log(this.$route)
    this.setMenu()
    this.getProjectGroupBySchoolId()

    // this.menu = [...this.$store.state.menuList, ...this.$store.state.menu2List]
    // console.log(this.menu)
  },
  methods: {
    setMenu () {
      console.log(this.$route, '------1')
      this.menu = []
      this.menuList = []
      this.tabList = []
      this.$store.state.schoolMenuList.forEach((item) => {
        if (!item.childList) {
          this.menu.push(item)
        } else {
          item.childList.forEach(items => {
            this.menu.push(items)
          })
        }
      })
      if (this.menu.some(item => this.$route.path === item.path)) {
        this.menu.forEach(item => {
          if (this.$route.path === item.path) {
            this.title = item.rightName
          }
        })
      } else {
        this.menuList = [...this.$store.state.schoolBreadcrumbList]
        // console.log(this.menuList)
        if (this.menuList.some(item => this.$route.path === item.path)) {
          const id = this.menuList.filter(item => this.$route.path === item.path)[0].id
          // console.log(id)
          this.setTabList(id)
        }
      }
      console.log(this.menu.some(item => this.$route.path === item.path),this.menu,'menu');
    },
    setTabList (id) {
      if (this.menuList.some(item => id === item.id)) {
        this.tabList.unshift(this.menuList.filter(item => id === item.id)[0])
        // console.log(this.menuList.filter(item => id === item.id)[0])
        if (this.tabList[0].partherId) {
          this.setTabList(this.tabList[0].partherId)
        }
      }
      console.log(this.tabList,'this.tabList')
    },
    selectChange (val) {
      // console.log(val);
      sessionStorage.setItem('pgId', val)
      this.$emit('gobreak')
    },
    // 获取所属专业群列表
    async getProjectGroupBySchoolId () {
      try {
        const { data: res } = await this.$api.getProjectGroupBySchoolId({schoolId: sessionStorage.getItem('schoolId')})
    
        if (res.code === 2000) {
          // console.log(res)

          if (res.data.length > 0) {

            this.selectoptions = [...res.data.map(item => {
              return {
                value: item.id,
                label: item.name
              }
            })]
            this.selectValue = this.selectoptions[0].value
            this.$store.dispatch("change_schmenu", false);
            sessionStorage.setItem('pgId', this.selectValue)
            this.$emit('gobreak')

          } else {
            this.selectoptions = []
            this.$store.dispatch("change_schmenu", true);
            sessionStorage.removeItem('pgId')
            this.$emit('gobreak')
          }
        } else {
          this.$message.error(res.msg)
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-tabs{
  min-width: 1160px;
  .tab {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #52596F;
    padding: 20px;
    border-bottom: 1px solid #DFE5EF;
    .zyqselect{
      margin-left: 20px;
    }
    &.active1 {
      display: flex;
      justify-content: space-between;
      ::v-deep .el-button{
        padding: 0;
        img{
          margin-right: 5px;
          position: relative;
          top: -1px;
        }
      }
    }
    .icon {
      margin-left: 4px;
      font-size: 18px;
    }
  }
}
</style>
