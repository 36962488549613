import router from "./router";
import store from "./store";
import { queryUserRight } from "@/API/modules/authority.js";
import { Message } from "element-ui";
import schoolHome from '@/views/school/home'
import provinceHome from '@/views/province/home'
import operationHome from '@/views/operation/home'
import authorityHome from '@/views/authority/home'
import layout from '@/layout'
// console.log(router);
const linkList = ["/Login", "/401", "/TeacherPortrait",
  "/StudentPortrait",
  "/province_dialog",
  "/teaStu_dialog",
  "/zykCourseData",
  "/SPOCCourseData",
  "/ClassroomTeaching",
  "/ClassroomTeachingClass",
  "/MOOCCourseData"];
const screenList = [
  {
    id: '2',
    path: '/screen-dev',
    path2: '/dynamic'
  },
  {
    id: '3',
    path: '/Overview'
  },
  {
    id: '6',
    path: '/rankingList'
  },
  {
    id: '7',
    path: '/realTime'
  },
  {
    id: '8',
    path: '/dataAsset',
    path2: '/dataAsset2'
  },
]
router.beforeEach(async (to, from, next) => {
  // console.log(to);
  // document.title = to.meta.title;
  if (document.getElementById("scrollTop")) {
    // console.log(document.getElementById('scrollTop'));
    document.getElementById("scrollTop").scroll(0, 0);
  }
  let routerShow = store.state.routerShow;
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let routerLinkList = store.state.routerLink;
  if (linkList.indexOf(to.path) == -1) {
    if (!routerShow) {
      if (userInfo && userInfo.token) {
        try {
          const { data: res } = await queryUserRight({ userId: userInfo.id, dataSource: sessionStorage.getItem('dataSource') });
          if (res.code === 2000) {
            if (res.data.schoolManage) {
              let schoolManageArr = res.data.schoolManage.split(',')
              if (schoolManageArr.includes('5')) {
                localStorage.setItem('isDemo', true)
              } else {
                localStorage.removeItem('isDemo')
              }
            }
            store.commit('CHANGE_schoolManage', res.data.schoolManage)
            store.commit('CHANGE_userManage', res.data.userManage)
            store.commit('CHANGE_selectRightIdList', res.data.selectRightIdList)
            let datas = routerInit(res.data);
            console.log(datas, 'router');
            let needRoutes = filterRoute(res.data.rightTreeNodeList)
            routerAdd(needRoutes, '')
            // needRoutes.forEach(item => {
            //   router.addRoute(item)
            //   if (item.childList && item.childList.length > 0) {
            //     item.childList.forEach(citem => {
            //       router.addRoute(item.name, citem)
            //       if (citem.childList && citem.childList.length > 0) {
            //         citem.childList.forEach(citemchildren => {
            //           router.addRoute(citem.name, citemchildren)
            //         })
            //       }
            //     })
            //   }
            // })
            store.dispatch("routerList", datas);
            next(
              to.fullPath
            ) // hack方法 确保addRoutes已完成
            // next({ ...to, replace: true })
          } else {
            // Message.error(res.msg);
            if (res.msg == "您的登录状态已失效！") {
              localStorage.removeItem("userInfo");
              next({
                path: "/Login",
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        localStorage.removeItem("userInfo");
        next({
          path: "/Login",
        });
      }
    } else {
      if (routerLinkList.indexOf(to.path) == -1) {
        next({ path: "/401" });
      } else {
        // console.log(to.path, '111');
        next();
      }
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  window.scroll(0, 0);
});
//addRoute添加路由需要循环指定上级路由进行添加
function routerAdd(data, name) {
  if (data && data.length > 0) {
    data.forEach(item => {
      if (name) {
        router.addRoute(name, item)
      } else {
        router.addRoute(item)
      }
      if (item.childList && item.childList.length > 0) {
        routerAdd(item.childList, item.name)
      }
    })
  }
}
function routerInit(data, router) {
  let list = {
    operationMenuList: [],
    schoolBreadcrumbList: [],
    provinceMenuList: [],
    authorityMenuList: [],
    routerLink: [],

  };
  data.rightTreeNodeList.forEach((val) => {
    if (val.type == "school") {
      list.schoolBreadcrumbList = showMenu(val.childList)
    }
    if (val.type == "province") {
      list.provinceMenuList = showMenu(val.childList)
    }
    if (val.type == "operation") {
      list.operationMenuList = showMenu(val.childList)
    }
    if (val.type == "authority") {
      list.authorityMenuList = showMenu(val.childList)
    }
  });
  list.routerLink = reuterChange(data.rightTreeNodeList);
  screenList.forEach(item => {
    if (store.state.userManage.split(',').includes(item.id)) {
      list.routerLink.push(item.path)
      if (item.path2) {
        list.routerLink.push(item.path2)
      }
    }
  })

  return list;
}
//对菜单隐藏显示进行控制
function showMenu(data) {
  if (data && data.length > 0) {
    data = data.filter(item => {
      if (item.childList && item.childList.length > 0) {
        item.childList = showMenu(item.childList)
      }
      return item.show == true
    })
  }
  return data
}
let routerLink = [
  "/homePage",
  // "/Overview",
  // "/realTime",
  // "/screen-dev",
  // "/dynamic",
  // "/realTime_testWebsocket",
  // "/rankingList",
  // "/userDistribution",
  // "/dataAsset",
  // "/dataAsset2",
  "/editNewMedia",
  "/TeacherPortrait",
  "/StudentPortrait",
  "/zykCourseData",
  "/SPOCCourseData",
  "/ClassroomTeaching",
  "/ClassroomTeachingClass",
  "/MOOCCourseData",
  '/school/dashBoard',
  '/school/zykCourseData',
  "/school/zykCourseDetails",
  "/school/courseRanking",
  "/school/joinRanking",
  "/school/teacherRanking",
  "/school/courseRanking",
  "/school/studentRanking",
  "/school/ResourceConstructionDetails",
  "/school/classteachStatisticsdetail",
  "/school/classteachStatisticsteachdetail",
  '/school/classteachStatisticsreport',
  '/school/classteachStatisticsphb',
  '/school/classteachStatisticsstupj',
  '/school/classteachStatisticsstugk',
  '/school/classteachStatisticsjoinact',
  '/school/classteachStatisticsjoinbefore',
  '/school/classteachStatisticsjoinmid',
  '/school/CourseResourcesStatistics',
  '/school/CourseBankStatistics',
  '/school/classteachStatisticsjoinlater',
  '/school/teacherResourceConstruction',
  '/school/classteachStatistics/index',
  "/school/zjyCourseDetails",
  "/school/zjyClasseDetails",
  "/school/zjyStudentDetails",
  "/school/MOOCCourseDetails",
  "/school/UserBehaviorRecord",
  "/school/zyqUserDetails",
  "/school/zyqCourseDetails",
  "/school/zyqUserDetailsList",
  "/school/DistributionDetails",
  "/school/StudentPortrait",
  "/school/TeacherPortrait",
  "/school/SPOCCourseData",
  "/school/ClassroomTeaching",
  "/school/ClassroomTeachingClass",
  "/school/MOOCCourseData"
];
function loadView(view) {
  if (process.env.NODE_ENV === 'development') {
    return (resolve) => require([`@/views${view}`], resolve)
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => import(`@/views${view}`)
  }
}
//处理路由
function filterRoute(data) {
  data.forEach((res) => {
    if (res.component) {
      res.name = res.path
      if (res.component == 'Layout') {
        if (res.type == 'authority') {
          res.component = authorityHome
        } else if (res.type == 'school') {
          res.component = schoolHome
        } else if (res.type == 'province') {
          res.component = provinceHome
        } else if (res.type == 'operation') {
          res.component = operationHome
        } else if (res.type == 'layout') {
          res.component = layout
        }
      } else {
        res.component = loadView(res.component)
      }
    }
    if (res.childList && res.childList.length > 0) {
      filterRoute(res.childList);
    }
  });
  return data;
}
function reuterChange(data) {
  data.forEach((res) => {
    if (res.path && res.show) {
      routerLink.push(res.path);
    }
    if (res.childList && res.childList.length > 0) {
      reuterChange(res.childList);
    }
  });
  return routerLink;
}
