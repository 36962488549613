// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 首页数据请求
// 获取课程getCourseSystem
export const getCourseSystem = params => {
  return request({
    url: '/api/zyqapi/baseinfo/getCourseSystem',
    method: 'get',
    params: params
  })
}

// 获取课程列表
// https://qun.icve.com.cn/manager/course/getList
export const getList = params => {
  return request({
    url: '/api/manager/course/getList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取省份列表
// /basicInfo/getProvinceList
export const getProvinceList = params => {
  return request({
    url: '/api/basicInfo/getProvinceList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 获取基本数据
// /schoolProject/getProjectBasicData
export const getProjectBasicData = params => {
  return request({
    url: '/api/schoolProject/getProjectBasicData',
    method: 'post',
    data: params
  })
}
// 获取最近一个月的申请项目
// /schoolProject/getNewDateProjectList
export const getNewDateProjectList = params => {
  return request({
    url: '/api/schoolProject/getNewDateProjectList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 获取项目名称列表
// /schoolProject/getProjectNameList
export const getProjectNameList = params => {
  return request({
    url: '/api/schoolProject/getProjectNameList',
    method: 'post',
    data: params
  })
}
// 获取项目详情
// /schoolProject/getProjectDetail
export const getProjectDetail = params => {
  return request({
    url: '/api/schoolProject/getProjectDetail',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 获取职教云统计数据
// /schoolProject/getZJYSchoolInfo 
export const getZJYSchoolInfo = params => {
  return request({
    url: '/api/schoolProject/getZjyApplicationSituation',
    method: 'post',
    data: params
  })
}
// 获取mooc计数据
// /schoolProject/getMoocSchoolInfo
export const getMoocSchoolInfo = params => {
  return request({
    url: '/api/schoolProject/getMoocCourseBuild',
    method: 'post',
    data: params
  })
}
