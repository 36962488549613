// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'


// 职教云统计
//课堂教学总览——课堂教学分布
export const zjyClassStatisticsgetAttendanceDistribution = params => {
  return request({
    url: '/api/zjyFaceTeach/getAttendanceDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}

//课堂教学总览——签到分析
export const zjyClassStatisticsgetSignAnalysis = params => {
  return request({
    url: '/api/zjyFaceTeach/getSignAnalysis',
    method: 'post',
    data: qs.stringify(params)
  })
}

//课堂教学总览——学生评价星级
export const zjyClassStatisticsgetFactStudentStar = params => {
  return request({
    url: '/api/zjyFaceTeach/getFactStudentStar',
    method: 'post',
    data: qs.stringify(params)
  })
}

//课堂教学总览——课堂教学活动
export const zjyClassStatisticsgetFaceTeachActivity = params => {
  return request({
    url: '/api/zjyFaceTeach/getFaceTeachActivity',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂教学统计--课堂报告
export const zjyClassStatisticsgetFaceTeachReport = params => {
  return request({
    url: '/api/zjyFaceTeach/getFaceTeachReport',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂教学统计--导出课堂报告
export const zjyClassStatisticsexportFaceTeachReport = params => {
  return request({
    url: '/api/zjyFaceTeach/exportFaceTeachReport',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 课堂教学统计--课堂教学分析
export const zjyClassStatisticsgetFaceTeachAnalysisList = params => {
  return request({
    url: '/api/zjyFaceTeach/getFaceTeachAnalysisList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂教学统计--导出课堂教学分析
export const zjyClassStatisticsexportFaceTeachAnalysisList = params => {
  return request({
    url: '/api/zjyFaceTeach/exportFaceTeachAnalysisList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}


// 课堂统计数据
export const zjyClassStatisticsactivityAttendance = params => {
  return request({
    url: '/api/zjyFaceTeach/activityAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}


// 课堂学习排行榜——分数榜
export const zjyClassStatisticsgetRankForScoreList = params => {
  return request({
    url: '/api/zjyFaceTeach/getRankForScoreList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂学习排行榜——活跃度榜
export const zjyClassStatisticsgetRankForActivation = params => {
  return request({
    url: '/api/zjyFaceTeach/getRankForActivation',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂学习排行榜——学习积极榜
export const zjyClassStatisticsgetRankForActiveStudy = params => {
  return request({
    url: '/api/zjyFaceTeach/getRankForActiveStudy',
    method: 'post',
    data: qs.stringify(params)
  })
}


// 课堂活动参与——课前
export const zjyClassStatisticsgetActivityJoinBefore = params => {
  return request({
    url: '/api/zjyFaceTeach/getActivityJoinBefore',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂活动参与——课中
export const zjyClassStatisticsgetActivityAlready = params => {
  return request({
    url: '/api/zjyFaceTeach/getActivityAlready',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂活动参与——课后
export const zjyClassStatisticsgetActivityAfter = params => {
  return request({
    url: '/api/zjyFaceTeach/getActivityAfter',
    method: 'post',
    data: qs.stringify(params)
  })
}


// 学生评价——学生打星星评价统计
export const zjyClassStatisticsgetStarStatistics = params => {
  return request({
    url: '/api/zjyFaceTeach/getStarStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 学生评价——学生评价评语列表
export const zjyClassStatisticsgetStuEvaluationStar = params => {
  return request({
    url: '/api/zjyFaceTeach/getStuEvaluationStar',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 学生概况——参与活动统计
export const zjyClassStatisticsgetStudentProfileList = params => {
  return request({
    url: '/api/zjyFaceTeach/getStudentProfileList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 学生概况——导出参与活动统计


// 学生概况——评价词云
export const zjyClassStatisticsgetEvaluationWord = params => {
  return request({
    url: '/api/zjyFaceTeach/getEvaluationWord',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 班级课堂教学详情
export const zjyClassStatisticsgetAssistClassActivityDetail = params => {
  return request({
    url: '/api/zjyFaceTeach/getAssistClassActivityDetail',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 导出班级课堂教学详情
export const zjyClassStatisticsexportAssistClassActivityDetail = params => {
  return request({
    url: '/api/zjyFaceTeach/exportAssistClassActivityDetail',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}






