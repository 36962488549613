// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'


// 获取活跃课程排行榜
export const zjyRankingStatisticsgetActivityCourseRank = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityCourseRank',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 保存活跃课程排行榜算法
export const zjyRankingStatisticssaveCourseRankSet = params => {
    return request({
        url: '/api/zjyRankStatistics/saveCourseRankSet',
        method: 'post',
        data: params
    })
}

// 获取活跃课程排行榜算法详情
export const zjyRankingStatisticsgetCourseDetailBySchoolId = params => {
    return request({
        url: '/api/zjyRankStatistics/getCourseDetailBySchoolId',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 获取活跃课程排行榜详情
export const zjyRankingStatisticsgetActivityCourseDetail = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityCourseDetail',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出活跃课程排行榜
export const zjyRankingStatisticsexportCourseList = params => {
    return request({
        url: '/api/zjyRankStatistics/exportCourseList',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}


// 获取活跃教师排行榜
export const zjyRankingStatisticsgetActivityTchrRank = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityTchrRank',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 保存活跃教师排行榜算法
export const zjyRankingStatisticssaveTchrRankSet = params => {
    return request({
        url: '/api/zjyRankStatistics/saveTchrRankSet',
        method: 'post',
        data: params
    })
}

// 获取活跃教师排行榜算法详情
export const zjyRankingStatisticsgetTchrDetailBySchoolId = params => {
    return request({
        url: '/api/zjyRankStatistics/getTchrDetailBySchoolId',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 获取活跃教师排行榜详情
export const zjyRankingStatisticsgetActivityTchrDetail = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityTchrDetail',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出活跃教师排行榜
export const zjyRankingStatisticsexportTchrList = params => {
    return request({
        url: '/api/zjyRankStatistics/exportTchrList',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}


// 获取活跃学生排行榜
export const zjyRankingStatisticsgetActivityStuRank = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityStuRank',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 保存活跃学生排行榜算法
export const zjyRankingStatisticssaveStuRankSet = params => {
    return request({
        url: '/api/zjyRankStatistics/saveStuRankSet',
        method: 'post',
        data: params
    })
}

// 获取活跃学生排行榜算法详情
export const zjyRankingStatisticsgetStuDetailBySchoolId = params => {
    return request({
        url: '/api/zjyRankStatistics/getStuDetailBySchoolId',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 获取活跃学生排行榜详情
export const zjyRankingStatisticsgetActivityStuDetail = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityStuDetail',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出活跃学生排行榜
export const zjyRankingStatisticsexportStuList = params => {
    return request({
        url: '/api/zjyRankStatistics/exportStuList',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}


// 获取班级出勤排行榜
export const zjyRankingStatisticsgetActivityClassRank = params => {
    return request({
        url: '/api/zjyRankStatistics/getActivityClassRank',
        method: 'post',
        data: qs.stringify(params)
    })
}

// 导出班级出勤排行榜
export const zjyRankingStatisticsexportActivityClassRank = params => {
    return request({
        url: '/api/zjyRankStatistics/exportActivityClassRank',
        method: 'post',
        responseType: 'blob',
        data: qs.stringify(params)
    })
}

// 
export const zjyRankingStatisticsgetSubListForProfileList = params => {
    return request({
        url: '/api/zjyFaceTeach/getSubListForProfileList',
        method: 'post',
        data: qs.stringify(params)
    })
}