// 引入axios 实例
import request from '@/utils/request'
import qs from 'qs'

// 师生信息

// 教师统计
// 课程建设数量
export const getCourseBuild = params => {
  return request({
    url: '/api/teachers/getCourseBuild',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课程建设数量-课程建设列表
export const getTeacherSpocList = params => {
  return request({
    url: '/api/teachers/getTeacherSpocList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 教师行为分布
export const getLogTypeStatistics = params => {
  return request({
    url: '/api/teachers/getLogTypeStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源上传数量
export const getResourceUpload = params => {
  return request({
    url: '/api/teachers/getResourceUpload',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源上传数量-资源上传列表
export const getResourceUploadCourseList = params => {
  return request({
    url: '/api/teachers/getResourceUploadCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 教师人数分析
export const getTeaAnalysis = params => {
  return request({
    url: '/api/teachers/getTeaAnalysis',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 教师详情（列表）
export const getTeacherDetail = params => {
  return request({
    url: '/api/teachers/getTeacherDetail',
    method: 'post',
    data: params
  })
}
// 教师详情（列表）导出
export const exportTeacherDetail = params => {
  return request({
    url: '/api/teachers/exportTeacherDetail',
    method: 'post',
    responseType: 'blob',
    data: params
  })
}

// 获取院系列表
export const getSubs = params => {
  return request({
    url: '/api/teachers/getSubs',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 教师画像

// 教师信息
export const getTeaInfo = params => {
  return request({
    url: '/api/teaPortrait/getTeaInfo',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源建设
export const getResourcesConstruction = params => {
  return request({
    url: '/api/teaPortrait/getResourcesConstruction',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 上传素材——资源来源
export const getResourceSource = params => {
  return request({
    url: '/api/teaPortrait/getResourceSource',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 上传素材——按媒体类型统计
export const getMediaType = params => {
  return request({
    url: '/api/teaPortrait/getMediaType',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 上传素材——活跃资源占比
export const getResourceActive = params => {
  return request({
    url: '/api/teaPortrait/getResourceActive',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 资源库课程数据列表
export const getZykStandardCourseList = params => {
  return request({
    url: '/api/teaPortrait/getZykStandardCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 导出资源库课程数据列表
export const exportZykStandardCourseList = params => {
  return request({
    url: '/api/teaPortrait/exportZykStandardCourseList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 资源分析
export const getResourceDistribution = params => {
  return request({
    url: '/api/teaPortrait/getResourceDistribution',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 日志类型分布
export const getLogTypeStatistics1 = params => {
  return request({
    url: '/api/teaPortrait/getLogTypeStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂活动日历
export const getFaceCalendar = params => {
  return request({
    url: '/api/teaPortrait/getFaceCalendar',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 日志分析
export const getLogDayStatistics = params => {
  return request({
    url: '/api/teaPortrait/getLogDayStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂与教学——spoc课程数
export const getSpocCourse = params => {
  return request({
    url: '/api/teaPortrait/getSpocCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂与教学——spoc课程列表
export const getSPOCCourseList = params => {
  return request({
    url: '/api/teaPortrait/getSPOCCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——spoc课程列表导出
export const exportSPOCCourseList = params => {
  return request({
    url: '/api/teaPortrait/exportSPOCCourseList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}

// 课堂与教学——发起课程活动
export const getCourseAction = params => {
  return request({
    url: '/api/teaPortrait/getCourseAction',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——课堂活动偏好
export const getClassPreference = params => {
  return request({
    url: '/api/teaPortrait/getClassPreference',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——测试成绩分数分布
export const getExamGrade = params => {
  return request({
    url: '/api/teaPortrait/getExamGrade',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——出勤数据
export const getAttendance = params => {
  return request({
    url: '/api/teaPortrait/getAttendance',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——教学日志
export const getTeachingInteraction = params => {
  return request({
    url: '/api/teaPortrait/getTeachingInteraction',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 课堂与教学——授课学生总数
export const getTeachingStuCount = params => {
  return request({
    url: '/api/teaPortrait/getTeachingStuCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——授课教师课程数据(课堂教学班级数据)
export const getTeachingStuCountList = params => {
  return request({
    url: '/api/teaPortrait/getTeachingStuCountList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——授课教师课程数据(课堂教学班级数据)导出
export const exportTeachingStuCountList = params => {
  return request({
    url: '/api/teaPortrait/exportTeachingStuCountList',
    method: 'post',
    responseType: 'blob',
    data: qs.stringify(params)
  })
}


// 课堂与教学——批阅作业-批阅考试
export const getWorkCount = params => {
  return request({
    url: '/api/teaPortrait/getWorkCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 课堂与教学——作业-考试总数
export const getTeachingWork = params => {
  return request({
    url: '/api/teaPortrait/getTeachingWork',
    method: 'post',
    data: qs.stringify(params)
  })
}

// MOOC与教学服务——建设MOOC课程(门）
export const getMoocCourse = params => {
  return request({
    url: '/api/teaPortrait/getMoocCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// MOOC与教学服务——总期数
export const getMOOCState = params => {
  return request({
    url: '/api/teaPortrait/getMOOCState',
    method: 'post',
    data: qs.stringify(params)
  })
}
// MOOC与教学服务——mooc课程列表
export const getMoocCourseList = params => {
  return request({
    url: '/api/teaPortrait/getMoocCourseList',
    method: 'post',
    data: qs.stringify(params)
  })
}
// MOOC与教学服务——答疑讨论（次）
export const getMoocDiscuss = params => {
  return request({
    url: '/api/teaPortrait/getMoocDiscuss',
    method: 'post',
    data: qs.stringify(params)
  })
}
// MOOC与教学服务——作业总数-考试总数
export const GetMoocWorkCount = params => {
  return request({
    url: '/api/teaPortrait/GetMoocWorkCount',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 学生统计
// 学生数量
export const getStuCount = params => {
  return request({
    url: '/api/students/getStuCount',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学生行为分布
export const getDayUserStatistics = params => {
  return request({
    url: '/api/students/getDayUserStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学生日志分布
export const getStudentLog = params => {
  return request({
    url: '/api/students/getStudentLog',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 学生（列表）
export const getStudentDetail = params => {
  return request({
    url: '/api/students/getStudentDetail',
    method: 'post',
    data: params
  })
}
export const exportStudentDetailList = params => {
  return request({
    url: '/api/students/exportTeachBehavior',
    method: 'post',
    responseType: 'blob',
    data: params
  })
}


// 学生画像

// 学生信息
export const getStuInfo = params => {
  return request({
    url: '/api/studentImage/getStuInfo',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志分布
export const getLogTypeStatistics2 = params => {
  return request({
    url: '/api/studentImage/getLogTypeStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 校内课程-资源库-mooc学院
export const getZykCourse = params => {
  return request({
    url: '/api/studentImage/getZykCourse',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 我的学习日志（仅SPOC）
export const GetSPOCStudy = params => {
  return request({
    url: '/api/studentImage/GetSPOCStudy',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 用户行为时间段
export const getHourAction = params => {
  return request({
    url: '/api/studentImage/getHourAction',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 活动数据
export const getActivity = params => {
  return request({
    url: '/api/studentImage/getActivity',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 成绩
export const getGrades = params => {
  return request({
    url: '/api/studentImage/getGrades',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志类型分布
export const getLogType = params => {
  return request({
    url: '/api/studentImage/getLogType',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 日志分析
export const getLogDayStatistics1 = params => {
  return request({
    url: '/api/studentImage/getLogDayStatistics',
    method: 'post',
    data: qs.stringify(params)
  })
}
